import {WorkflowExecutionStage, WorkflowExecutionStageState} from '../../types/workflow';
import React from 'react';
import {Icon, Spin} from '@gravity-ui/uikit';
import {CircleCheckFill, CircleXmarkFill} from '@gravity-ui/icons';

type WorkflowStepExecutionStatusProps = {
    stage: WorkflowExecutionStage;
    finalState: WorkflowExecutionStageState['final_state'];
    finished: boolean;
};

export const WorkflowStepExecutionStatus: React.FC<WorkflowStepExecutionStatusProps> = (
    props: WorkflowStepExecutionStatusProps,
) => {
    const {stage, finalState, finished} = props;

    if ((!finished && stage !== 'to_do') || stage === 'running') {
        return <Spin size="xs" />;
    }

    if (stage === 'done') {
        return finalState === 'done' ? (
            <Icon data={CircleCheckFill} />
        ) : (
            <Icon data={CircleXmarkFill} />
        );
    }

    if (stage === 'to_do') {
        return null;
    }

    return null;
};
