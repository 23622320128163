import React, {useCallback, useEffect, useState} from 'react';
import {JupytOperationsList} from 'features/Jupyter/components/JupytOperationsList/JupytOperationsList';
import {useNebiusDispatch} from 'store/nebius-dispatch';
import {
    loadNotebookJupytList,
    resumeJupyt,
    setCurrentJupytAlias,
} from '../../store/actions/jupyt/jupyt';
import {useSelector} from 'react-redux';
import {selectJupytOperations} from 'features/Jupyter/store/selectors/jupyt';
import {
    checkIfCurrentJupytSuspended,
    selectCurrentJupytAlias,
    selectRunningCells,
} from 'features/Jupyter/store/selectors/notebook';
import {
    executeNotebookWithRequiredJupyt,
    interruptExecution,
    restartKernel,
} from 'features/Jupyter/store/actions/execute';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global/index';
import isEmpty from 'lodash/isEmpty';
import {NebiusRootState} from 'store/reducers';
import {modalCreateJupytSlice} from '../../store/slices/modals/modal-create-jupyt';
import {JUPYT_OPERATIONS_PAGE_ID} from 'features/Jupyter/constants';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {getNavigationPathAttributes} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation/navigation';
import {getNotebookDownloadUrl} from 'features/Jupyter/api/kernel/content-manager';

export const JupytOperationsListContainer = () => {
    const [isSuspendedInFlight, setSuspendedFlight] = useState(false);
    const dispatch = useNebiusDispatch();

    const cluster = useSelector(getCluster);

    const operations = useSelector(selectJupytOperations);

    const currentJupytAlias = useSelector(selectCurrentJupytAlias);
    const isCurrentJupytSuspended = useSelector(checkIfCurrentJupytSuspended);

    const path = useSelector(getPath);

    const attributes = useSelector(getNavigationPathAttributes);

    const jupytOperationsLink = `/${cluster}/${JUPYT_OPERATIONS_PAGE_ID}`;

    const onRunNotebookClick = useCallback(() => {
        dispatch(executeNotebookWithRequiredJupyt());
    }, [operations]);

    const onStopNotebookClick = useCallback(() => {
        dispatch(interruptExecution());
    }, []);

    const onResumeKernelClick = useCallback(() => {
        dispatch(
            resumeJupyt({
                alias: currentJupytAlias,
            }),
        ).then(() => {
            setSuspendedFlight(true);
            dispatch(loadNotebookJupytList());
        });
    }, [currentJupytAlias]);

    const isNotebookRunning = useSelector((state: NebiusRootState) =>
        isEmpty(selectRunningCells(state)),
    );

    const hanldeCurrentJupytAliasChange = useCallback((alias: string) => {
        dispatch(setCurrentJupytAlias({alias}));
    }, []);

    const handleEmptyJupytSelectClick = useCallback(() => {
        dispatch(modalCreateJupytSlice.actions.open());
    }, []);

    const [notebookDownloadUrl, setNotebookDownloadUrl] = useState('');

    useEffect(() => {
        getNotebookDownloadUrl({path, cluster}).then((url) => {
            setNotebookDownloadUrl(url);
        });
    }, [path]);

    const onRestartKernelClick = useCallback(() => {
        dispatch(restartKernel());
    }, []);

    useEffect(() => {
        setSuspendedFlight(!isCurrentJupytSuspended);
    }, [isCurrentJupytSuspended]);

    return (
        <JupytOperationsList
            path={path}
            isSuspendedInFlight={isSuspendedInFlight}
            isCurrentJupytSuspended={isCurrentJupytSuspended}
            notebookDownloadUrl={notebookDownloadUrl}
            attributes={attributes}
            isNotebookRunning={isNotebookRunning}
            operations={operations}
            currentJupytAlias={currentJupytAlias}
            onCurrentJupytAliasChange={hanldeCurrentJupytAliasChange}
            onRunNotebookClick={onRunNotebookClick}
            onStopNotebookClick={onStopNotebookClick}
            onResumeKernelClick={onResumeKernelClick}
            jupytOperationsLink={jupytOperationsLink}
            onEmptyJupytSelectClick={handleEmptyJupytSelectClick}
            onRestartKernelClick={onRestartKernelClick}
        />
    );
};
