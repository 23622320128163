import {Loader} from '@gravity-ui/uikit';
import {useUpdater} from '@ytsaurus-ui-platform/src/ui/hooks/use-updater';
import {WorkflowRunsTabComponent} from 'features/Orchestracto/components/WorkflowRunsTabComponent/WorkflowRunsTabComponent';
import {
    loadWorkflowRunsFormCypress,
    resetWorkflowState,
} from 'features/Orchestracto/store/actions/runs';
import {selectIsRunsLoaded} from 'features/Orchestracto/store/selectors/runs';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {StepDetailsModal} from '../StepDetailsModal/StepDetailsModal';

export const WorkflowRunsTabContainer: React.FC = () => {
    const dispatch = useDispatch();
    const isRunsLoaded = useSelector(selectIsRunsLoaded);

    const update = useCallback(() => {
        dispatch(loadWorkflowRunsFormCypress());
    }, []);

    useUpdater(update);

    useEffect(() => {
        return () => {
            dispatch(resetWorkflowState());
        };
    }, []);

    if (!isRunsLoaded) {
        return <Loader />;
    }

    return (
        <>
            <WorkflowRunsTabComponent />
            <StepDetailsModal />
        </>
    );
};
