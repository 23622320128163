import {Dialog} from '@gravity-ui/uikit';
import Yson from '@ytsaurus-ui-platform/src/ui/components/Yson/Yson';
import {selectModalStepDetails} from 'features/Orchestracto/store/selectors/modals';
import {stepDetailsModalSlice} from 'features/Orchestracto/store/slices/modals/step-details-modal';
import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import block from 'bem-cn-lite';
import './StepDetailsModal.scss';
import MetaTable from '@ytsaurus-ui-platform/src/ui/components/MetaTable/MetaTable';

const b = block('step-details-modal');

export const StepDetailsModal: React.FC = () => {
    const step = useSelector(selectModalStepDetails);
    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        dispatch(stepDetailsModalSlice.actions.close());
    }, []);

    const items = useMemo(
        () => [
            {
                key: 'Task params',
                value: <Yson value={step?.task_params} />,
            },
            {
                key: 'Secrets',
                value: <Yson value={step?.secrets} />,
            },
            {
                key: 'Args',
                value: <Yson value={step?.args} />,
            },
            {
                key: 'Outputs',
                value: <Yson value={step?.outputs} />,
            },
        ],
        [step],
    );

    const visible = Boolean(step);

    if (!visible) {
        return null;
    }

    return (
        <Dialog open={true} onClose={onClose} size="m" className={b()}>
            <Dialog.Header caption={`${step?.step_id} details`} />
            <Dialog.Body>
                <MetaTable items={items} rowGap={4} />
            </Dialog.Body>
        </Dialog>
    );
};
