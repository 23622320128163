import React from 'react';

import format from '@ytsaurus-ui-platform/src/ui/common/hammer/format';
import {Label} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';

import type {JupytServerHealthType, JupytServerStateType} from '../../api/jupyt';

const b = block('jupyt-state');

import './JupytState.scss';

type LabelTheme =
    | 'normal'
    | 'info'
    | 'danger'
    | 'warning'
    | 'success'
    | 'utility'
    | 'unknown'
    | 'clear';

const THEME_MAP: Record<JupytServerHealthType | JupytServerStateType, LabelTheme> = {
    good: 'success',
    pending: 'info',
    failed: 'danger',
    active: 'success',
    inactive: 'unknown',
    untracked: 'danger',
};

type JupytStateProps = {
    size?: 'm';
    className?: string;
    suspended?: boolean;
    capitalize?: boolean;
    state?: JupytServerHealthType | JupytServerStateType;
};

export const JupytState = ({state, suspended, size, className, capitalize}: JupytStateProps) => {
    if (!state) {
        return format.NO_VALUE;
    }

    const theme = THEME_MAP[state];

    if (suspended && state !== 'inactive') {
        return (
            <Label size={size} className={b({capitalize}, className)} theme={'unknown'}>
                suspended
            </Label>
        );
    }

    return (
        <Label size={size} className={b({capitalize}, className)} theme={theme}>
            {state}
        </Label>
    );
};
