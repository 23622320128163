import type {TConnection} from '@gravity-ui/graph';
import type {WorkflowDocumentType, WorkflowStepState} from '../types/workflow';
import ELK from 'elkjs';
import type {WorkflowBlock} from '../types/graph';

export async function buildGraphFromWorkflow({
    workflow,
}: {
    workflow: WorkflowDocumentType;
}): Promise<{
    blocks: WorkflowBlock[];
    connections: TConnection[];
}> {
    const blocks: WorkflowBlock[] = [];
    const connections: TConnection[] = [];

    const blocksMap: Record<string, WorkflowBlock> = {};

    workflow.steps.forEach((step, index) => {
        const block = {
            is: 'block-action',
            id: step.step_id,
            x: 220 * index,
            y: 0,
            width: 200,
            height: 100,
            selected: false,
            name: step.step_id,
            anchors: [],
        };
        blocksMap[block.id] = block;
        blocks.push(block);

        step.depends_on?.forEach((dependency) => {
            connections.push({
                sourceBlockId: dependency,
                targetBlockId: step.step_id,
            });
        });
    });

    await calculatePositions({blocks, connections, blocksMap});

    return {
        blocks,
        connections,
    };
}
const elk = new ELK();

export const calculatePositions = async ({
    blocks,
    blocksMap,
    connections,
}: {
    blocks: WorkflowBlock[];
    blocksMap: Record<string, WorkflowBlock>;
    connections: TConnection[];
}) => {
    const offset = '200';
    const graph = {
        id: 'root',
        layoutOptions: {
            'elk.algorithm': 'layered',
            'elk.direction': 'RIGHT',
            'spacing.nodeNode': offset, // Horizontal distance between nodes
            'spacing.nodeNodeBetweenLayers': offset, // Distance between layers (columns)
            'spacing.edgeNode': offset, // Distance between nodes and edges
            'spacing.edgeEdge': offset, // Distance between edges
            'spacing.edgeEdgeBetweenLayers': '0', // Distance between edges in layers
            'nodePlacement.strategy': 'SIMPLE', // Node placement strategy
            'elk.layered.layering.strategy': 'INTERACTIVE', // Interactive level management
            'elk.layered.crossingMinimization.semiInteractive': 'true', // Minimizing edge intersections
            'elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED', // Align nodes within levels
            'elk.layered.thoroughness': '100',
        },
        children: blocks.map((block) => {
            return {
                id: block.id as string,
                width: block.width,
                height: block.height,
            };
        }),
        edges: connections.map((connection) => {
            return {
                id: `${connection.sourceBlockId as string}#${connection.targetBlockId as string}`,
                sources: [connection.sourceBlockId as string],
                targets: [connection.targetBlockId as string],
            };
        }),
    };

    return elk.layout(graph).then((result) => {
        result.children?.forEach((child) => {
            const block = blocksMap[child.id];

            if (block) {
                block.x = child.x || block.x;
                block.y = child.y || block.y;
            }
        });
    });
};

export async function buildGraphFromWorkflowWithStatuses({
    workflow,
    workflowStepsStateMap,
}: {
    workflow: WorkflowDocumentType;
    workflowStepsStateMap: Record<string, WorkflowStepState>;
}): Promise<{
    blocks: WorkflowBlock[];
    connections: TConnection[];
}> {
    const {blocks, connections} = await buildGraphFromWorkflow({workflow});

    const blocksWithStatus = blocks.map((block) => {
        return {
            ...block,
            meta: workflowStepsStateMap[block.id as string],
        };
    });

    return {
        blocks: blocksWithStatus,
        connections,
    };
}
