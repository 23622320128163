import React, {useEffect, useRef} from 'react';
import block from 'bem-cn-lite';

import TractoMonacoEditor from 'components/TractoMonacoEditor/TractoMonacoEditor';
import type * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import * as nbformat from '@jupyterlab/nbformat';
import {MarkdownCellSource} from '../MarkdownCellSource/MarkdownCellSource';

import 'highlight.js/styles/github.css';

import './JupyterCellSource.scss';
import {JupyterCellQa} from '../../../../../shared/qa';
import {setupMonacoJupyterExtensions} from 'components/TractoMonacoEditor/extensions';

const b = block('jupyter-cell-source');

export type BlockSourcePropsType = {
    cell: nbformat.ICell;
    isDirty?: boolean;
    isEditable?: boolean;
    isRunning?: boolean;
    isFocused?: boolean;
    onChange: (value: string) => void;
    onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
    onMarkdownBlur?: () => void;
    onImagePaste?: (value: {name: string; type: string; base64: string}) => void;
};

export function JupyterCellSource({
    cell,
    isDirty,
    isRunning,
    isFocused,
    isEditable,
    onChange,
    onFocus,
    onMarkdownBlur,
    onImagePaste,
}: BlockSourcePropsType) {
    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();
    const source = cell.source;
    const type = cell.cell_type;

    let content;
    let executionCount;

    useEffect(() => {
        if (isEditable) {
            editorRef?.current?.focus();
        }
    }, [isEditable]);

    switch (type) {
        case 'code': {
            executionCount = cell.execution_count;
            const value = Array.isArray(source) ? source.join('') : source || '';

            content = (
                <TractoMonacoEditor
                    editorRef={editorRef}
                    language={'python'}
                    value={value}
                    onChange={onChange}
                    setupExtensions={setupMonacoJupyterExtensions}
                />
            );

            break;
        }
        case 'markdown': {
            content = (
                <MarkdownCellSource
                    source={source}
                    onChange={onChange}
                    editorRef={editorRef}
                    isEditable={isEditable}
                    onMarkdownBlur={onMarkdownBlur}
                    attachments={cell.attachments}
                    onImagePaste={onImagePaste}
                />
            );
            break;
        }
        default: {
            content = <div>{`Cell Type ${type} not supported...`}</div>;
        }
    }

    const executionNumber = isRunning ? '*' : executionCount;

    const headerContent = executionNumber ? `[${executionNumber}]: ` : null;
    return (
        <div
            data-qa={JupyterCellQa.JupyterCellSource}
            className={b({focused: isFocused, dirty: isDirty})}
            onFocus={onFocus}
        >
            <div className={b('row')}>
                <pre data-qa={JupyterCellQa.JupyterCellSourceHeader} className={b('header')}>
                    {headerContent}
                </pre>
                <div className={b('content')}>{content}</div>
            </div>
        </div>
    );
}
