import React from 'react';
import {Flex} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';
import './BlueprintRunDetailsComponent.scss';
import {BlueprintImageParamsComponent} from '../BlueprintImageParamsComponent/BlueprintImageParamsComponent';
import {BlueprintAttachedFilesComponent} from '../BlueprintAttachedFilesComponent/BlueprintAttachedFilesComponent';

const b = block('blueprint-run-details');

export const BlueprintRunDetailsComponent: React.FC = () => {
    return (
        <Flex direction="column" className={b()}>
            <BlueprintImageParamsComponent className={b('section')} isReadonly={true} />
            <BlueprintAttachedFilesComponent className={b('section')} isReadonly={true} />
            <div className={b('stub')}>TODO: Rendered content</div>
        </Flex>
    );
};
