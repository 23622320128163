import React from 'react';
import {settings} from '@nebius-ui/spd/src/utils/settings';
import {OperationMonitoringTabProps, UIFactory} from '@ytsaurus-ui-platform/src/ui/UIFactory';
import {MonitoringStubComponent} from './components/MonitoringStubComponent/MonitoringStubComponent';
import {SchedulerPoolDashboardContainer} from './containers/SchedulerPoolDashboardContainer/SchedulerPoolDashboardContainer';
import {MonitoringMarkdown} from './components/MonitoringMarkdown/MonitoringMarkdown';
import type {PathParameters} from '@ytsaurus-ui-platform/src/ui/store/location';
import {getMonitoringPreparedState, monitoringParams} from './store/monitoring.location';

const getSystemMonitoringTab: UIFactory['getSystemMonitoringTab'] = () => {
    return {
        title: 'System Monitoring',
        component: (props) => (
            <MonitoringStubComponent pluginPoint="SystemMonitoringTab" restProps={props} />
        ),
    };
};

const getVersionMonitoringLink: UIFactory['getVersionMonitoringLink'] = () => {
    return {
        urlTemplate: '/fake/link',
        title: 'VersionMonitoringLink',
    };
};

const getMonitoringForAccounts: UIFactory['getMonitoringForAccounts'] = () => {
    return {
        component: (props: {cluster: string; account: string}) => (
            <MonitoringStubComponent pluginPoint="MonitoringForAccounts" restProps={props} />
        ),
    };
};

const getMonitoringForBundle: UIFactory['getMonitoringForBundle'] = () => {
    return {
        component: (props: {cluster: string; tablet_cell_bundle: string; bundleData: any}) => (
            <MonitoringStubComponent pluginPoint="MonitoringForBundle" restProps={props} />
        ),
    };
};

const getMonitoringForOperation: UIFactory['getMonitoringForOperation'] = () => {
    return {
        component: (props: OperationMonitoringTabProps) => (
            <MonitoringStubComponent pluginPoint="MonitoringForOperation" restProps={props} />
        ),
    };
};

const getMonitorComponentForJob: UIFactory['getMonitorComponentForJob'] = () => {
    return (props: {cluster: string; job_descriptor: string; from?: number; to?: number}) => (
        <MonitoringStubComponent pluginPoint="MonitorComponentForJob" restProps={props} />
    );
};

const getMonitoringComponentForChyt: UIFactory['getMonitoringComponentForChyt'] = () => {
    return {
        component: (props: {cluster: string; alias: string}) => (
            <MonitoringStubComponent pluginPoint="MonitoringComponentForChyt" restProps={props} />
        ),
        urlTemplate: 'urlTemplate of MonitoringComponentForChyt',
        title: 'MonitoringComponentForChyt',
    };
};

const getSchedulingExtraTabs: UIFactory['getSchedulingExtraTabs'] = () => {
    return [
        {
            name: 'monitoring',
            component: SchedulerPoolDashboardContainer,
        },
    ];
};

const plugins = new Map();

plugins.set('text', MonitoringMarkdown);

settings.set({plugins});

export const monitoringUrlMapping: Record<string, PathParameters> = {
    ['/*/scheduling/monitoring']: [monitoringParams, getMonitoringPreparedState],
};

export const monitoring: Pick<
    UIFactory,
    | 'getSystemMonitoringTab'
    | 'getVersionMonitoringLink'
    | 'getMonitoringForAccounts'
    | 'getMonitoringForBundle'
    | 'getMonitoringForOperation'
    | 'getMonitorComponentForJob'
    | 'getMonitoringComponentForChyt'
    | 'getSchedulingExtraTabs'
> = {
    getSystemMonitoringTab,
    getVersionMonitoringLink,
    getMonitoringForAccounts,
    getMonitoringForBundle,
    getMonitoringForOperation,
    getMonitorComponentForJob,
    getMonitoringComponentForChyt,
    getSchedulingExtraTabs,
};
