import type {ICell, IDisplayData} from '@jupyterlab/nbformat';

export const extractCellId = (cell: ICell) => {
    return cell.metadata.cell_id as string;
};

const WIDGET_VIEW_MIMETYPE = 'application/vnd.jupyter.widget-view+json';

export type WidgetDisplayData = IDisplayData & {
    data: {[WIDGET_VIEW_MIMETYPE]: {model_id: string}};
};

export const isWidgetData = (data: WidgetDisplayData | IDisplayData): data is WidgetDisplayData => {
    return (data as WidgetDisplayData)?.data[WIDGET_VIEW_MIMETYPE]?.model_id !== undefined;
};
