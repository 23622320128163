import React from 'react';
import {WorkflowEditorContainer} from 'features/Orchestracto/containers/WorkflowEditorContainer/WorkflowEditorContainer';
import FlexSplitPane from '@ytsaurus-ui-platform/src/ui/components/FlexSplitPane/FlexSplitPane';
import block from 'bem-cn-lite';
import './WorkflowTabComponent.scss';
import {WorkflowDetailsContainer} from 'features/Orchestracto/containers/WorkflowDetailsContainer/WorkflowDetailsContainer';
import {WorkflowGraphContainer} from '../../containers/WorkflowGraphContainer/WorkflowGraphContainer';

const b = block('workflow-tab-component');

type WorkflowTabComponentProps = {};

export const WorkflowTabComponent: React.FC<WorkflowTabComponentProps> = () => {
    return (
        <div className={b()}>
            <WorkflowDetailsContainer />
            <FlexSplitPane className={b('split-pane')} direction={FlexSplitPane.HORIZONTAL}>
                <WorkflowGraphContainer />
                <WorkflowEditorContainer />
            </FlexSplitPane>
        </div>
    );
};
