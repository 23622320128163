import {SecretListItem} from 'components/SecretsList/SecretsList';
import React, {ComponentProps, useCallback, useEffect, useMemo, useState} from 'react';
import {SecretStoreTabComponent} from 'features/SecretStore/components/SecretStoreTabComponent/SecretStoreTabComponent';
import {useNebiusDispatch} from 'store/nebius-dispatch';
import {SecretStore} from 'features/SecretStore/types/secrets';
import {
    loadSecretStoreFromCypress,
    saveSecretStoreToCypress,
} from 'features/SecretStore/store/actions/secrets';

export const SecretStoreTabContainer: React.FC = () => {
    const dispatch = useNebiusDispatch();
    const [secretStore, setSecretStore] = useState<SecretStore | null>(null);

    useEffect(() => {
        dispatch(loadSecretStoreFromCypress()).then((data) => {
            setSecretStore(data);
        });
    }, []);

    const value = useMemo(() => {
        if (!secretStore) {
            return [];
        }

        return Object.entries(secretStore.secrets).map(([secretKey, secretValue]) => {
            return {
                name: secretKey,
                value: secretValue.value,
            };
        });
    }, [secretStore]);

    const onUpdate = useCallback((value: SecretListItem[]) => {
        const newSecretsDocument = value.reduce(
            (acc, item) => {
                acc.secrets[item.name] = {
                    value: item.value,
                };

                return acc;
            },
            {secrets: {}} as SecretStore,
        );

        setSecretStore(newSecretsDocument);
    }, []);

    const onSaveClick = useCallback(() => {
        if (secretStore) {
            dispatch(saveSecretStoreToCypress({secretStore}));
        }
    }, [secretStore]);

    const props: ComponentProps<typeof SecretStoreTabComponent> = {
        loading: !secretStore,
        value,
        onUpdate,
        onSaveClick,
    };

    return <SecretStoreTabComponent {...props} />;
};
