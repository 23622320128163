import {PayloadAction, createSlice} from '@reduxjs/toolkit';

type DocumentType = '' | 'document' | 'workflow' | 'notebook' | 'secret-store';

type ModalCreateDocumentInitialState = {
    documentType: DocumentType;
};

const initialState: ModalCreateDocumentInitialState = {
    documentType: '',
};

export const modalCreateDocumentSlice = createSlice({
    name: 'common.modal.create-document',
    initialState,
    reducers: {
        open: (state, action: PayloadAction<{documentType: DocumentType}>) => {
            state.documentType = action.payload.documentType;
        },
        close: (state) => {
            state.documentType = '';
        },
    },
});
