import React, {Component} from 'react';
import {ResolveThunks, connect} from 'react-redux';
import cn from 'bem-cn-lite';

import AlertEvents from '@ytsaurus-ui-platform/src/ui/components/AlertEvents/AlertEvents';
import CollapsibleSection from '@ytsaurus-ui-platform/src/ui/components/CollapsibleSection/CollapsibleSection';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import Error from '@ytsaurus-ui-platform/src/ui/components/Error/Error';
import Icon from '@ytsaurus-ui-platform/src/ui/components/Icon/Icon';

import Specification from '@ytsaurus-ui-platform/src/ui/pages/operations/OperationDetail/tabs/details/Specification/Specification';
import Runtime from '@ytsaurus-ui-platform/src/ui/pages/operations/OperationDetail/tabs/details/Runtime/Runtime';

import {showEditPoolsWeightsModal} from '@ytsaurus-ui-platform/src/ui/store/actions/operations';

import {getOperationAlertEvents} from '@ytsaurus-ui-platform/src/ui/store/selectors/operations/operation';

import './Details.scss';
import {UI_COLLAPSIBLE_SIZE} from '@ytsaurus-ui-platform/src/ui/constants/global';
import {NebiusRootState} from 'store/reducers';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {OperationView} from 'features/Tractorun/components/OperationView/OperationView';

export {operationProps} from '@ytsaurus-ui-platform/src/ui/pages/operations/OperationDetail/tabs/details/Runtime/Runtime';

const block = cn('operation-details');

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ResolveThunks<typeof mapDispatchToProps>;

type DetailsProps = StateProps & DispatchProps;

type OperationDescription = Record<string, any> | undefined;

type OperationsDetailsType = {
    runtime?: {
        name: string;
        progress: Record<string, any>;
    }[];
    specification: any;
    error?: Error;
};

class Details extends Component<DetailsProps> {
    handleEditClick = () => {
        const {operation, showEditPoolsWeightsModal} = this.props;
        showEditPoolsWeightsModal(operation);
    };

    getOperationDescription(): Record<string, any> {
        return (this.props.operation.description as unknown as OperationDescription) || {};
    }

    renderTractorun() {
        const {tractorun} = this.getOperationDescription();

        return (
            tractorun && (
                <CollapsibleSection
                    name="Tractorun"
                    className={block('description')}
                    size={UI_COLLAPSIBLE_SIZE}
                    marginDirection="bottom"
                    collapsed={false}
                >
                    <OperationView value={tractorun} />
                </CollapsibleSection>
            )
        );
    }

    renderExtra() {
        const {extra} = this.getOperationDescription();

        return (
            extra && (
                <CollapsibleSection
                    name="Extra"
                    className={block('description')}
                    size={UI_COLLAPSIBLE_SIZE}
                    marginDirection="bottom"
                    collapsed={false}
                >
                    <OperationView value={extra} />
                </CollapsibleSection>
            )
        );
    }

    renderSpecification() {
        const {specification, cluster} = this.props;

        return (
            <CollapsibleSection
                name="Specification"
                className={block('specification')}
                size={UI_COLLAPSIBLE_SIZE}
                marginDirection="bottom"
            >
                <Specification specification={specification} cluster={cluster} />
            </CollapsibleSection>
        );
    }

    renderAlerts() {
        const {alertEvents} = this.props;
        return !alertEvents?.length ? null : (
            <CollapsibleSection name="Alerts" size={UI_COLLAPSIBLE_SIZE} marginDirection="bottom">
                <AlertEvents items={alertEvents} />
            </CollapsibleSection>
        );
    }

    renderError() {
        const {error} = this.props;

        return (
            error && (
                <div className={block('result')}>
                    <Error {...error} />
                </div>
            )
        );
    }

    renderRuntimeOverview() {
        return (
            <Button
                size="s"
                onClick={this.handleEditClick}
                title="Edit pools and weights"
                className={block('edit-button')}
            >
                <Icon awesome="pencil" />
                &nbsp;Edit
            </Button>
        );
    }

    renderRuntime() {
        const {runtime, operation, cluster} = this.props;

        return (
            runtime.length > 0 && (
                <CollapsibleSection
                    name="Runtime"
                    className={block('runtime')}
                    overview={this.renderRuntimeOverview()}
                    size={UI_COLLAPSIBLE_SIZE}
                    marginDirection="bottom"
                >
                    <Runtime runtime={runtime} operation={operation as any} cluster={cluster} />
                </CollapsibleSection>
            )
        );
    }

    render() {
        return (
            <div className={block()}>
                <div className={block('section')}>
                    {this.renderTractorun()}
                    {this.renderSpecification()}
                </div>

                <div className={block('section')}>
                    {this.renderExtra()}
                    {this.renderAlerts()}
                    {this.renderError()}
                    {this.renderRuntime()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: NebiusRootState) => {
    const cluster = getCluster(state);

    const {operations} = state;
    const {operation} = operations.detail;
    const details = (operations.detail.details || {}) as unknown as OperationsDetailsType;

    const runtime = details.runtime || [];
    const specification = details.specification;
    const error = details.error;

    return {
        cluster,
        operation,
        runtime,
        specification,
        alertEvents: getOperationAlertEvents(state),
        error,
    };
};

const mapDispatchToProps = {
    showEditPoolsWeightsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
