import React from 'react';
import {Flex} from '@gravity-ui/uikit';
import {BlueprintEditorContainer} from 'features/ImageBuilder/containers/BlueprintEditorContainer/BlueprintEditorContainer';
import block from 'bem-cn-lite';
import './BlueprintTabComponent.scss';
import {BlueprintCredentialsComponent} from '../BlueprintCredentialsComponent/BlueprintCredentialsComponent';
import {BlueprintAttachedFilesComponent} from '../BlueprintAttachedFilesComponent/BlueprintAttachedFilesComponent';
import {BlueprintImageParamsComponent} from '../BlueprintImageParamsComponent/BlueprintImageParamsComponent';
import {BlueprintTemplateComponent} from '../BlueprintTemplateComponent/BlueprintTemplateComponent';

const b = block('blueprint-tab');

export const BlueprintTabComponent: React.FC = () => {
    return (
        <Flex direction="column" className={b()}>
            <BlueprintTemplateComponent className={b('section')} />
            <BlueprintImageParamsComponent className={b('section', {s: true})} />
            <BlueprintAttachedFilesComponent className={b('section', {s: true})} />
            <BlueprintCredentialsComponent className={b('section', {s: true})} />
            <BlueprintEditorContainer className={b('section')} />
        </Flex>
    );
};
