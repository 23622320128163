import * as React from 'react';
import withLazyLoading from '@ytsaurus-ui-platform/src/ui/hocs/withLazyLoading';

export const importWidgetManager = () => {
    return import(/* webpackChunkName: "jupyter-widget-manager" */ './index.impl');
};

export const JupyterCellWidgetOutputLazy = withLazyLoading(
    React.lazy(async () => {
        return {
            default: (await importWidgetManager()).JupyterCellWidgetOutput,
        };
    }),
);
