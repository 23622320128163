import React from 'react';

import block from 'bem-cn-lite';
import {Flex, Text} from '@gravity-ui/uikit';

import './JupytSelectOptions.scss';
import type {JupytServerHealthType, JupytServerStateType} from 'features/Jupyter/api/jupyt';
import {JupytState} from '../../JupytState/JupytState';

const b = block('jupyt-select-option');

type JupytSelectOptionProps = {
    value: string;
    status?: JupytServerHealthType | JupytServerStateType;
    qa?: string;
    suspended?: boolean;
};

export const JupytSelectOption: React.FC<JupytSelectOptionProps> = (
    props: JupytSelectOptionProps,
) => {
    const {value, status, qa, suspended} = props;

    return (
        <Flex title={value} justifyContent={'space-between'} className={b()}>
            <Text className={b('text')} variant="body-1" ellipsis={true} qa={qa}>
                {value}
            </Text>
            {status ? (
                <JupytState className={b('icon')} state={status} suspended={suspended} />
            ) : null}
        </Flex>
    );
};
