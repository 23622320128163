import {useSelector} from 'react-redux';
import {
    selectIsWorkflowValid,
    selectStepsMap,
    selectWorkflow,
} from '../../store/selectors/workflow';
import React from 'react';
import {WorkflowDocumentType} from '../../types/workflow';
import {GraphContainer} from '../GraphContainer/GraphContainer';
import {buildGraphFromWorkflow} from '../../utils/graph';

export const WorkflowGraphContainer = () => {
    const workflow = useSelector(selectWorkflow);
    const isWorkflowValid = useSelector(selectIsWorkflowValid);
    const stepsMap = useSelector(selectStepsMap);

    const getData = React.useCallback(() => {
        if (!isWorkflowValid) {
            throw new Error('Invalid workflow format');
        }

        const parsedWorkflow = JSON.parse(workflow) as unknown as WorkflowDocumentType;

        return buildGraphFromWorkflow({workflow: parsedWorkflow});
    }, [workflow, isWorkflowValid]);

    return <GraphContainer stepsMap={stepsMap} getData={getData} />;
};
