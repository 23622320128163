import React from 'react';
import block from 'bem-cn-lite';
import {Icon, IconData} from '@gravity-ui/uikit';

import './NotebookDropArea.scss';

const b = block('notebook-drop-area');

type NotebookDropAreaProps = {
    qa?: string;
    title: string;
    icon: IconData;
    onClick?: () => void;
    onDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
    onDragOver?: (event: React.DragEvent<HTMLDivElement>) => void;
};

export const NotebookDropArea: React.FC<NotebookDropAreaProps> = ({
    qa,
    title,
    icon,
    onClick,
    onDrop,
    onDragOver,
}) => {
    return (
        <div className={b()} onClick={onClick} onDrop={onDrop} onDragOver={onDragOver} data-qa={qa}>
            <div className={b('title')}>{title}</div>
            <Icon className={b('icon')} size={80} data={icon} />
        </div>
    );
};
