export enum KeyEnum {
    DOWN = 'ArrowDown',
    UP = 'ArrowUp',
    ENTER = 'Enter',
    ESC = 'Escape',
    S = 's',
    P = 'p',
    C = 'c',
    SHIFT = 'Shift',
}
