import {NotebookPlaceholder} from 'features/Jupyter/components/NotebookPlaceholder/NotebookPlaceholder';
import {notebookSlice} from 'features/Jupyter/store/slices/notebook';
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {readFileAsString} from 'utils/file';

const useNotebookPlaceholderProps = () => {
    const dispatch = useDispatch();

    const onFileChange = useCallback(async (value: FileList | null) => {
        const file = value && value[0];

        if (!file) {
            return;
        }

        const notebook = JSON.parse(await readFileAsString(file));

        dispatch(notebookSlice.actions.setNotebook({notebook}));
    }, []);

    const onAddFirstCellClick = useCallback(() => {
        dispatch(notebookSlice.actions.addCellAfter({currentIndex: 0, type: 'code'}));
    }, []);

    return {
        onFileChange,
        onAddFirstCellClick,
    };
};

export const NotebookPlaceholderContainer: React.FC = () => {
    const notebookPlaceholderProps = useNotebookPlaceholderProps();

    return <NotebookPlaceholder {...notebookPlaceholderProps} />;
};
