import React from 'react';
import TractoDetails from 'features/Tractorun/containers/Details/Details';
import {useSelector} from 'react-redux';
import {NebiusRootState} from 'store/reducers';

const selectIsTractorunOperation = (state: NebiusRootState) =>
    state.operations.detail.operation.$attributes?.spec?.annotations?.is_tractorun;

export const Tractorun = {
    renderDetailsTab: ({Details}: {Details: React.ComponentType}) => {
        const isTractorunOperation = useSelector(selectIsTractorunOperation);

        if (isTractorunOperation) {
            return <TractoDetails />;
        }

        return <Details />;
    },
};
