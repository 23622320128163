import {Loader} from '@gravity-ui/uikit';
import React, {useMemo} from 'react';
import {JupytSecretsMapType} from '../../../../../shared/types/jupyt/secrets';
import {mapJupytSecretsToList} from '../../../../../shared/utils/jupyt/secrets';
import {SecretsList} from 'components/SecretsList/SecretsList';
import block from 'bem-cn-lite';
import './JupytSecretsTab.scss';

const b = block('jupyt-secrets-tab');

type JupytSecretsTabProps = {
    secrets: JupytSecretsMapType | null;
    renderSecretsEditor: () => React.ReactNode;
};
export const JupytSecretsTab: React.FC<JupytSecretsTabProps> = ({secrets, renderSecretsEditor}) => {
    let content;

    const secretsList = useMemo(() => {
        return mapJupytSecretsToList(secrets);
    }, [secrets]);

    if (secrets) {
        content = (
            <div className={b('secrets-map-wrapper')}>
                <div className={b('editor-wrapper')}>{renderSecretsEditor()}</div>
                <SecretsList onUpdate={() => {}} value={secretsList} isReadonly={true} />
            </div>
        );
    } else {
        content = (
            <div className={b('loader')}>
                <Loader size="s" />
            </div>
        );
    }

    return <div className={b()}>{content}</div>;
};
