import React, {useCallback, useEffect, useState} from 'react';
import {GraphComponent} from 'features/Orchestracto/components/GraphComponent/GraphComponent';
import {
    Graph,
    GraphState,
    HookGraphParams,
    type TBlock,
    type TConnection,
    TGraphEventCallbacks,
    useGraph,
} from '@gravity-ui/graph';
import {WorkflowStepContainer} from '../WorkflowStepContainer/WorkflowStepContainer';
import {WorkflowBlock} from 'features/Orchestracto/types/graph';
import {WorkflowStepType} from 'features/Orchestracto/types/workflow';

const RENDER_NODE_AS_REACT_COMPONENT = [1, 1, 0];

const config: HookGraphParams = {
    viewConfiguration: {
        constants: {
            block: {
                SCALES: RENDER_NODE_AS_REACT_COMPONENT,
            },
        },
    },
};

type GraphConfig<T extends TBlock> = {
    blocks: T[];
    connections: TConnection[];
};

type GraphContainerProps<T extends TBlock> = {
    getData: () => Promise<GraphConfig<T>>;
    stepsMap: Record<string, WorkflowStepType>;
};

export const GraphContainer = <T extends TBlock>({getData, stepsMap}: GraphContainerProps<T>) => {
    const inited = React.useRef(false);

    const {graph, setEntities, start, zoomTo} = useGraph(config);
    const [error, setError] = useState<Error | null>(null);

    const renderBlock = useCallback(
        (graph: Graph, block: WorkflowBlock) => {
            return <WorkflowStepContainer stepsMap={stepsMap} graph={graph} block={block} />;
        },
        [stepsMap],
    );

    useEffect(() => {
        async function createGraph() {
            try {
                const {blocks, connections} = await getData();

                setError(null);

                if (inited.current) {
                    setEntities({blocks, connections});
                } else {
                    inited.current = true;
                    setEntities({
                        blocks,
                        connections,
                    });
                    zoomTo('center');
                }
            } catch (getDataError: any) {
                setError(getDataError);
            }
        }

        createGraph();
    }, [getData]);

    const onStateChanged: TGraphEventCallbacks['onStateChanged'] = useCallback(
        ({state}) => {
            if (state === GraphState.ATTACHED) {
                start();
            }
        },
        [graph],
    );

    return (
        <GraphComponent
            graph={graph}
            error={error}
            onStateChanged={onStateChanged}
            renderBlock={renderBlock}
        />
    );
};
