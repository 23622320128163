import React from 'react';
import unipika from '@ytsaurus-ui-platform/src/ui/common/thor/unipika';
import ypath from '@ytsaurus-ui-platform/src/ui/common/thor/ypath';

import MetaTable from '@ytsaurus-ui-platform/src/ui/components/MetaTable/MetaTable';
import Yson from '@ytsaurus-ui-platform/src/ui/components/Yson/Yson';

type OperationViewProps = {
    value: Record<string, any>;
};

export const OperationView: React.FC<OperationViewProps> = ({value}) => {
    const yPathValue = ypath.getValue(value);

    const keys = Object.keys(yPathValue).sort();

    const settings = {...unipika.prepareSettings(), escapeWhitespace: false};

    const items = keys.map((key) => ({
        key,
        value: <Yson settings={settings} value={yPathValue[key]} />,
    }));

    return <MetaTable items={items} />;
};
