import React, {useCallback, useEffect, useState} from 'react';
import {useNebiusDispatch} from 'store/nebius-dispatch';
import {JupytSecretsMapType} from '../../../../../shared/types/jupyt/secrets';
import {JupytSecretsTab} from 'features/Jupyter/components/JupytSecretsTab/JupytSecretsTab';
import {SecretsEditorDialogButton} from 'features/Jupyter/components/SecretsEditorDialog/SecretsEditorDialog';
import {getJupytSecrets, setJupytSecrets} from '../../store/actions/jupyt/secrets';

type JupytSecretsTabContainerProps = {
    alias: string;
};

export const JupytSecretsTabContainer: React.FC<JupytSecretsTabContainerProps> = React.memo(
    ({alias}) => {
        const dispatch = useNebiusDispatch();
        const [secrets, setSecrets] = useState<JupytSecretsMapType | null>(null);

        useEffect(() => {
            dispatch(getJupytSecrets({alias})).then((secrets) => {
                setSecrets(secrets);
            });
        }, []);

        const onAddSecrets = useCallback(
            async (secrets: JupytSecretsMapType) => {
                await dispatch(setJupytSecrets({alias, secrets}));

                const newSecrets = await dispatch(getJupytSecrets({alias}));

                setSecrets(newSecrets);
            },
            [alias],
        );

        const renderSecretsEditor = useCallback(() => {
            return <SecretsEditorDialogButton secrets={secrets} onAdd={onAddSecrets} />;
        }, [secrets, onAddSecrets]);

        return <JupytSecretsTab secrets={secrets} renderSecretsEditor={renderSecretsEditor} />;
    },
);
