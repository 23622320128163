import {NebiusThunkDispatch} from 'store/nebius-dispatch';
import {NebiusRootState} from 'store/reducers';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import ypath from '@ytsaurus-ui-platform/src/ui/common/thor/ypath';
import {runsSlice} from '../slices/runs';
import {WorkflowRunData, WorkflowRunListItem} from 'features/Orchestracto/types/runs';
import {ytApiV3} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import {createNebiusAsyncThunk} from '../../../../store/nebius-async-thunk';
import {
    getCurrentWorkflowLocationConfig,
    getCurrentWorkflowRunConfig,
    getCurrentWorkflowRunsPath,
} from '../../utils/workflow';
import {wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import type {
    WorkflowDocumentType,
    WorkflowExecutionStage,
    WorkflowExecutionState,
    WorkflowExecutionStatus,
    WorkflowStepState,
} from '../../types/workflow';
import {BatchResultsItem} from '@ytsaurus-ui-platform/src/shared/yt-types';

type ListResponse = {
    key: string;
    creation_time: string;
    orc_trigger_type?: string;
    orc_result_status?: WorkflowExecutionStatus;
};

export const loadWorkflowRunsFormCypress = () => {
    return async (dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        const {workflowId, orchestractoRootPath} = getCurrentWorkflowLocationConfig(
            getPath(getState()),
        );

        const runPath = getCurrentWorkflowRunsPath({orchestractoRootPath, workflowId});

        const attributes: Array<keyof ListResponse> = [
            'creation_time',
            'key',
            'orc_trigger_type',
            'orc_result_status',
        ];

        const result = await ytApiV3.list({
            path: runPath,
            attributes,
        });

        const runs: WorkflowRunListItem[] = result.map((item: any) => {
            const attributes: ListResponse = ypath.getAttributes(item);

            return {
                key: attributes.key,
                status: attributes.orc_result_status || 'unknown',
                triggerType: attributes.orc_trigger_type,
                startTime: attributes.creation_time,
            };
        });

        dispatch(
            runsSlice.actions.setRuns({
                runs: runs.sort((a, b) => b.startTime.localeCompare(a.startTime)),
            }),
        );
    };
};

export const loadWorkflowRunState = createNebiusAsyncThunk<WorkflowRunData, {runId: string}>(
    'orchestracto.actions.load-workflow-state',
    async ({runId}, thunkAPI): Promise<WorkflowRunData> => {
        const path = getPath(thunkAPI.getState());

        const {orchestractoRootPath, workflowId} = getCurrentWorkflowLocationConfig(path);

        const {workflowExecutionStatePath, workflowRunContentPath, workflowResultPath} =
            getCurrentWorkflowRunConfig({
                orchestractoRootPath,
                workflowId,
                runId,
            });

        const executeBatchPromise = ytApiV3.executeBatch<
            WorkflowExecutionStatus | WorkflowExecutionState | WorkflowDocumentType
        >({
            parameters: {
                requests: [
                    {
                        command: 'get',
                        parameters: {
                            path: `${workflowResultPath}/status`,
                        },
                    },
                    {
                        command: 'get',
                        parameters: {
                            path: workflowExecutionStatePath,
                        },
                    },
                    {
                        command: 'get',
                        parameters: {
                            path: workflowRunContentPath,
                        },
                    },
                ],
                output_format: 'json',
            },
        });

        const response = await wrapApiPromiseByToaster<
            BatchResultsItem<
                WorkflowExecutionStatus | WorkflowExecutionState | WorkflowDocumentType
            >[],
            'v3'
        >(executeBatchPromise, {
            toasterName: 'workflow-state-request',
            skipSuccessToast: true,
            batchType: 'v3',
            errorTitle: `Failed to load workflow run with ID: ${runId}`,
            errorContent: (error) => {
                return `[code ${error.code}] ${error.message}`;
            },
        });
        const workflowExecutionStatus = (response[0] as BatchResultsItem<WorkflowExecutionStatus>)
            .output!;
        const workflowExecutionState = (response[1] as BatchResultsItem<WorkflowExecutionState>)
            .output!;
        const workflowExecutionDocumentType = (
            response[2] as BatchResultsItem<WorkflowDocumentType>
        ).output!;

        const workflowStepsStateMap: Record<string, WorkflowStepState> = {};

        // eslint-disable-next-line guard-for-in
        for (const stageKey in workflowExecutionState.exec_stages) {
            const stage = stageKey as WorkflowExecutionStage;

            // eslint-disable-next-line guard-for-in
            for (const task in workflowExecutionState.exec_stages[stage]) {
                workflowStepsStateMap[task] = {
                    stage,
                    finalState: workflowExecutionState.exec_stages[stage][task].final_state,
                    finished: workflowExecutionState.exec_stages[stage][task].finished,
                };
            }
        }

        return {
            workflow: workflowExecutionDocumentType,
            workflowStatus: workflowExecutionStatus,
            workflowStepsStateMap,
            runId,
        };
    },
);

export const resetWorkflowState = createNebiusAsyncThunk(
    'orchestracto.actions.reset-workflow-runs-state',
    (_, thunkAPI) => {
        thunkAPI.dispatch(runsSlice.actions.clearState());
    },
);
