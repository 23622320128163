import * as React from 'react';
import block from 'bem-cn-lite';
import moment from 'moment/moment';
import {Button, Icon, Label, Table, TableColumnConfig} from '@gravity-ui/uikit';
import {CircleInfo} from '@gravity-ui/icons';
import ClipboardButton from '@ytsaurus-ui-platform/src/ui/components/ClipboardButton/ClipboardButton';
import {TableProps} from '@gravity-ui/uikit/build/esm/components/Table/Table';

import {getTagsTableData} from '../../store/selectors/repositories';

const b = block('tracto-registry-tags-list');

type Col = ReturnType<typeof getTagsTableData>[number];

interface TractoRegistryTagsListProps {
    tags: Col[];
    tractoRegistryUrl: string;
    repository: string;
    onRowClick: TableProps<Col>['onRowClick'];
}

export const TractoRegistryTagsList: React.FC<TractoRegistryTagsListProps> = ({
    tags,
    tractoRegistryUrl,
    repository,
    onRowClick,
}) => {
    const columns: TableColumnConfig<Col>[] = [
        {
            id: 'tag',
            name: 'Tag',
            template: (item) => {
                return (
                    <div onClick={(event) => event.stopPropagation()}>
                        <Label
                            type="copy"
                            theme="normal"
                            className={b('row-label')}
                            copyText={`${tractoRegistryUrl}/${repository}:${item.tag}`}
                        >
                            {item.tag}
                        </Label>
                    </div>
                );
            },
        },
        {
            id: 'created',
            name: 'Date created',
            template: (item) => {
                return moment(item.created).format('DD/MM/YYYY HH:mm:ss');
            },
        },
        {
            id: 'size',
            name: 'Size',
        },
        {
            id: 'sha256',
            name: 'Content Digest',
        },
        {
            id: 'architecture',
            name: 'Arch',
        },
        {
            id: 'details',
            name: '',
            template: () => {
                return (
                    <Button size="s" view="flat">
                        <Icon data={CircleInfo} />
                    </Button>
                );
            },
        },
    ];

    return (
        <div className={b()}>
            <h2>
                {repository}
                &nbsp;
                <ClipboardButton
                    view={'flat-secondary'}
                    text={`${tractoRegistryUrl}/${repository}`}
                />
            </h2>
            <h3>Tags</h3>
            <div>
                <Table data={tags} columns={columns} onRowClick={onRowClick} />
            </div>
        </div>
    );
};
