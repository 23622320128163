import React from 'react';
import block from 'bem-cn-lite';
import './BlueprintImageParamsComponent.scss';
import {TextArea} from '@gravity-ui/uikit';

const b = block('blueprint-image-params');

type BlueprintImageParamsComponentProps = {
    className?: string;
    isReadonly?: boolean;
};

export const BlueprintImageParamsComponent: React.FC<BlueprintImageParamsComponentProps> = ({
    className,
    isReadonly,
}) => {
    return (
        <div className={b(null, className)}>
            <div className="elements-heading elements-heading_size_xs">Image params</div>
            <TextArea placeholder="Image params..." disabled={isReadonly} />
        </div>
    );
};
