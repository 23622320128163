import React, {useCallback} from 'react';
import block from 'bem-cn-lite';
import {NotebookDropArea} from '../NotebookDropArea/NotebookDropArea';
import {ArrowDownToSquare, SquarePlus} from '@gravity-ui/icons';
import {FilePicker} from 'components/FilePicker/FilePicker';
import {NotebookPageQa} from '../../../../../shared/qa';

import './NotebookPlaceholder.scss';

const b = block('notebook-placeholder');

type NotebookPlaceholderProps = {
    onFileChange: (value: FileList | null) => void;
    onAddFirstCellClick: () => void;
};

export const NotebookPlaceholder: React.FC<NotebookPlaceholderProps> = ({
    onFileChange,
    onAddFirstCellClick,
}) => {
    const onDrop = useCallback(
        async (event: React.DragEvent<HTMLDivElement>) => {
            event.stopPropagation();
            event.preventDefault();
            onFileChange(event.dataTransfer.files);
        },
        [onFileChange],
    );

    const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
    }, []);

    return (
        <div className={b()}>
            <FilePicker onChange={onFileChange} className={b('option')}>
                <NotebookDropArea
                    title="Upload notebook"
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    icon={ArrowDownToSquare}
                />
            </FilePicker>
            <div className={b('option')}>
                <NotebookDropArea
                    qa={NotebookPageQa.AddCellControl}
                    title="Add cell"
                    icon={SquarePlus}
                    onClick={onAddFirstCellClick}
                />
            </div>
        </div>
    );
};
