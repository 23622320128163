import React, {useEffect} from 'react';
import {
    ECanChangeBlockGeometry,
    Graph,
    GraphCanvas,
    TGraphEventCallbacks,
    TGraphSettingsConfig,
} from '@gravity-ui/graph';
import {useThemeValue} from '@gravity-ui/uikit';
import {getGraphColors} from 'features/Orchestracto/utils/theme';
import ErrorBlock from '@ytsaurus-ui-platform/src/ui/components/Block/Block';
import block from 'bem-cn-lite';
import './GraphComponent.scss';
import type {WorkflowBlock} from '../../types/graph';

const b = block('graph-component');

type GraphComponentProps = {
    graph: Graph;
    error: Error | null;
    onStateChanged: TGraphEventCallbacks['onStateChanged'];
    renderBlock: (graph: Graph, block: WorkflowBlock) => React.ReactNode;
};

export const GraphComponent: React.FC<GraphComponentProps> = ({
    graph,
    error,
    onStateChanged,
    renderBlock,
}) => {
    const theme = useThemeValue();

    useEffect(() => {
        const settings: Partial<TGraphSettingsConfig> = {
            canChangeBlockGeometry: ECanChangeBlockGeometry.ALL,
        };

        if (graph) {
            graph.updateSettings(settings);
            graph.api.updateGraphColors(getGraphColors());
        }
    }, [graph, theme]);

    if (error) {
        return <ErrorBlock className={b('error')} error={error} />;
    }

    return (
        <GraphCanvas
            graph={graph}
            /*@ts-expect-error*/
            renderBlock={renderBlock}
            onStateChanged={onStateChanged}
        />
    );
};
