import React from 'react';
import block from 'bem-cn-lite';
import type {QAProps} from '@gravity-ui/uikit';
import * as nbformat from '@jupyterlab/nbformat';
import {JupyterAnsi} from 'features/Jupyter/components/JupyterAnsi/JupyterAnsi';
import {JupyterCellQa} from '../../../../../shared/qa';

import './JupyterCellOutput.scss';
import {JupyterCellWidgetOutputLazy} from '../../widget';
import {isWidgetData} from '../../utils/cell';

export type BlockOutputPropsType = {
    cell: nbformat.ICell;
} & QAProps;

const b = block('jupyter-cell-output');

export function JupyterCellOutput({cell}: BlockOutputPropsType) {
    const outputs = nbformat.isCode(cell) ? cell['outputs'] : [];

    return (
        <div className={b()} data-qa={JupyterCellQa.JupyterCellOutput}>
            <div className={b('content')}>
                {outputs.map((output, index: number) => {
                    return <OutputRow output={output} key={index} />;
                })}
            </div>
        </div>
    );
}

type OutputRowProps = {
    output: nbformat.IOutput;
};

function OutputRow({output}: OutputRowProps) {
    return (
        <div className={b('row', {type: output.output_type})}>
            <OutputRowComponent output={output} />
        </div>
    );
}

type OutputRowComponentProps = {
    output: nbformat.IOutput;
};

function OutputRowComponent({output}: OutputRowComponentProps) {
    if (nbformat.isStream(output)) {
        return <StreamOutput output={output} />;
    }

    if (nbformat.isExecuteResult(output)) {
        return <ContentDisplayOutput output={output} />;
    }

    if (nbformat.isDisplayData(output)) {
        if (isWidgetData(output)) {
            return <JupyterCellWidgetOutputLazy output={output} />;
        }

        return <ContentDisplayOutput output={output} />;
    }

    if (nbformat.isError(output)) {
        return <ErrorOutput output={output} />;
    }

    return null;
}

type StreamOutputProps = {
    output: nbformat.IStream;
};

function StreamOutput({output}: StreamOutputProps) {
    const className = b('output', {
        stdout: output['name'] === 'stdout',
        stderr: output['name'] === 'stderr',
    });
    const text = output['text'];
    const value = Array.isArray(text) ? text.join('') : text;

    return (
        <pre className={className}>
            <JupyterAnsi>{value}</JupyterAnsi>
        </pre>
    );
}

enum OutputData {
    ImagePng = 'image/png',
    TextHtml = 'text/html',
    TextPlain = 'text/plain',
}

type ContentDisplayOutputProps = {
    output: nbformat.IDisplayData | nbformat.IExecuteResult;
};

function ContentDisplayOutput({output}: ContentDisplayOutputProps) {
    const output_data = output['data'] || {};

    const imageData = output_data[OutputData.ImagePng];

    if (imageData) {
        const size = output['metadata']?.[OutputData.ImagePng] as
            | undefined
            | {height: string; width: string};
        const width = size?.width || 'auto';
        const height = size?.height || 'auto';

        return (
            <div className={b('output', {display: true})}>
                <img
                    src={`data:image/png;base64,${imageData}`}
                    width={width}
                    height={height}
                    alt=""
                />
            </div>
        );
    }

    if (OutputData.TextHtml in output_data) {
        const textHtml = output_data[OutputData.TextHtml];
        const value = Array.isArray(textHtml) ? textHtml.join('') : String(textHtml);

        return (
            <div
                className={b('output', {display: true})}
                dangerouslySetInnerHTML={{__html: value}}
            />
        );
    }

    if (OutputData.TextPlain in output_data) {
        const textPlain = output_data[OutputData.TextPlain];
        const value = Array.isArray(textPlain) ? textPlain.join('') : String(textPlain);

        return <pre className={b('output', {stdout: true})}>{value}</pre>;
    }

    return null;
}

type ErrorOutputProps = {
    output: nbformat.IError;
};

function ErrorOutput({output}: ErrorOutputProps) {
    return (
        <pre className={b('output', {stderr: true})}>
            <JupyterAnsi>{!output.traceback ? undefined : output.traceback.join('\n')}</JupyterAnsi>
        </pre>
    );
}
