import {useEffect, useRef} from 'react';

function useRefHook<T>(value: T) {
    const ref = useRef(value);
    ref.current = value;
    return ref;
}

export const useMetaKeyPressHook = (callback: (event: KeyboardEvent) => void) => {
    const ref = useRefHook(callback);

    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            const anyMetaKeyPressed = event.shiftKey || event.ctrlKey || event.metaKey;

            if (anyMetaKeyPressed) {
                ref.current(event);
            }
        };

        document.addEventListener('keydown', handler);

        return () => {
            document.removeEventListener('keydown', handler);
        };
    }, []);
};
