import moment from 'moment/moment';
import type {NebiusRootState} from '../../../../store/reducers';
import {
    bytesToSize,
    getBlobConfig,
    modifySpecificAttributeTypes,
    parseCreatedBy,
} from '../../utils';

export const getManifest = (tag: string) => (state: NebiusRootState) => {
    return state.nebius.tractoRegistry.manifests?.manifests?.find(
        (manifest) => manifest.tag === tag,
    );
};

export const getDockerFile = (tag: string) => (state: NebiusRootState) => {
    const image = state.nebius.tractoRegistry.manifests?.manifests?.find(
        (manifest) => manifest.tag === tag,
    );

    return image?.blob.history.map((item) => parseCreatedBy(item.created_by));
};

export const getTractoRegistryBlobConfig = (tag: string) => (state: NebiusRootState) => {
    const manifest = getManifest(tag)(state);

    if (manifest) {
        return Object.entries(getBlobConfig(manifest.blob)).map(([key, value]) =>
            modifySpecificAttributeTypes(key, value),
        );
    }

    return [];
};

export const getTagsTableData = (state: NebiusRootState) => {
    return state.nebius.tractoRegistry.manifests.manifests
        .map(({tag, manifest, blob}) => {
            const size = manifest.layers.reduce((acc, e) => {
                return acc + e.size;
            }, 0);
            return {
                tag,
                size: bytesToSize(size),
                created: blob.created,
                architecture: blob.architecture,
                sha256: manifest.config.digest,
                ociImage: manifest.mediaType === 'application/vnd.oci.image.index.v1+json',
            };
        })
        .sort((a, b) => moment(b.created).diff(moment(a.created)));
};

export const getLayersTableData = (tag: string) => (state: NebiusRootState) => {
    const manifest = getManifest(tag)(state);

    if (!manifest) {
        return [];
    }

    return manifest.manifest.layers.map((layer) => {
        return {
            digest: layer.digest,
            size: bytesToSize(layer.size),
        };
    });
};

export const isImageTabLoading = (state: NebiusRootState) =>
    state.nebius.tractoRegistry.manifests.status === 'loading' ||
    state.nebius.tractoRegistry.image.status === 'loading';
