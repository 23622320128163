import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {WorkflowStepType} from 'features/Orchestracto/types/workflow';

type ModalCopySolutionNotebookInitialState = {
    step: WorkflowStepType | null;
};

const initialState: ModalCopySolutionNotebookInitialState = {
    step: null,
};

export const stepDetailsModalSlice = createSlice({
    name: 'orchestracto.modal.step-details',
    initialState,
    reducers: {
        open: (state, action: PayloadAction<{step: WorkflowStepType}>) => {
            state.step = action.payload.step;
        },
        close: (state) => {
            state.step = null;
        },
    },
});
