import type {Graph} from '@gravity-ui/graph';
import {
    WorkflowStepComponent,
    WorkflowStepComponentProps,
} from 'features/Orchestracto/components/WorkflowStepComponent/WorkflowStepComponent';
import {stepDetailsModalSlice} from 'features/Orchestracto/store/slices/modals/step-details-modal';
import React, {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import type {WorkflowBlock} from '../../types/graph';
import {WorkflowStepType} from 'features/Orchestracto/types/workflow';

type WorkflowStepContainerProps = {
    graph: Graph;
    block: WorkflowBlock;
    stepsMap: Record<string, WorkflowStepType>;
};

export const WorkflowStepContainer: React.FC<WorkflowStepContainerProps> = ({
    graph,
    block,
    stepsMap,
}) => {
    const step = stepsMap[block.id as string];
    const dispatch = useDispatch();

    const onShowDetailsClick = useCallback(() => {
        dispatch(stepDetailsModalSlice.actions.open({step}));
    }, [step]);

    const workflowState: WorkflowStepComponentProps['workflowState'] = useMemo(() => {
        return block.meta;
    }, [block.meta]);

    if (!step) {
        return null;
    }

    return (
        <WorkflowStepComponent
            graph={graph}
            block={block}
            step={step}
            workflowState={workflowState}
            onShowDetailsClick={onShowDetailsClick}
        />
    );
};
