import type {TGraphColors} from '@gravity-ui/graph';
import cloneDeep_ from 'lodash/cloneDeep';

function computeCssVariable(name: string) {
    if (!name.startsWith('var(')) return name;

    const body = globalThis.document.body;
    if (!body) return name;

    const computedStyle = window.getComputedStyle(body);
    if (!computedStyle) return name;

    name = name.substring(4);
    name = name.substring(0, name.length - 1);

    return computedStyle.getPropertyValue(name).trim();
}

const graphColors: TGraphColors = {
    anchor: {
        background: 'var(--g-color-private-cool-grey-800-solid)',
        selectedBorder: 'var(--g-color-private-yellow-550-solid)',
    },
    block: {
        background: 'var(--g-color-private-cool-grey-250-solid)',
        text: 'var(--g-color-private-blue-1000-solid)',
        border: 'var(--g-color-private-cool-grey-1000-solid)',
        selectedBorder: 'var(--g-color-private-yellow-550-solid)',
    },
    canvas: {
        belowLayerBackground: 'var(--g-color-base-generic)',
        border: 'var(--g-color-text-secondary)',
        dots: 'var(--g-color-text-hint)',
        layerBackground: 'var(--g-color-base-background)',
    },
    connection: {
        background: 'var(--g-color-private-cool-grey-1000-solid)',
        selectedBackground: 'var(--g-color-private-yellow-550-solid)',
    },
    connectionLabel: {
        background: 'var(--g-color-base-generic)',
        hoverBackground: 'var(--g-color-base-generic-accent)',
        hoverText: 'var(--g-color-private-blue-1000-solid)',
        selectedBackground: 'var(--g-color-private-yellow-550-solid)',
        selectedText: 'var(--g-color-private-blue-1000-solid)',
        text: 'var(--g-color-private-blue-1000-solid)',
    },
    selection: {
        border: 'var(--g-color-private-yellow-550-solid)',
        background: 'var(--g-color-base-selection-hover)',
    },
};

function eachRecursive(obj: any, callback: (object: Record<string, string>, key: string) => void) {
    for (const k in obj) {
        if (typeof obj[k] == 'object' && obj[k] !== null) {
            eachRecursive(obj[k], callback);
        } else {
            callback(obj, k);
        }
    }
}

export function getGraphColors(): TGraphColors {
    const computedColors: TGraphColors = cloneDeep_(graphColors);

    eachRecursive(computedColors, (object, key) => {
        object[key] = computeCssVariable(object[key]);
    });

    return computedColors;
}
