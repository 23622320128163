import {
    WorkflowDocumentType,
    type WorkflowExecutionStatus,
    WorkflowLocationConfig,
    WorkflowRunConfig,
    WorkflowRunDocumentType,
} from '../types/workflow';
import {getOrchestractoRunPath} from './orchestracto';
import {WORKFLOW_RUN_PERSISTENT_STATUS} from '../constants/workflow';

export const getDefaultWorkflowContent = () => {
    return {
        _format_version: '0.1',
        triggers: [
            {
                trigger_type: 'cron',
                params: {
                    cron_expression: '*/30 * * * *',
                },
            },
        ],
        steps: [
            {
                task_type: 'python_code',
                task_params: {
                    code: "print('Hello world!')",
                    docker_image: 'docker.io/library/python:3.11',
                },
                step_id: 'my_first_step',
                secrets: [],
            },
        ],
    } satisfies WorkflowDocumentType;
};

export const getWorkflowRunFileContent = (
    workflowId: string,
    workflowContent: WorkflowDocumentType,
): WorkflowRunDocumentType => {
    return {
        workflow_id: workflowId,
        workflow: workflowContent,
    };
};

export const getCurrentWorkflowLocationConfig = (path: string): WorkflowLocationConfig => {
    // path should be //<orchestracto_dir>/workflows/<workflow_id>
    const pathParts = path.split('/');

    const workflowId = pathParts[pathParts.length - 1];

    // currently we don't have a convinient way to get orchestracto_dir path
    // so let's assume that our orchestracto_dir is 2 nodes up
    const orchestractoRootPath = pathParts.slice(0, pathParts.length - 2).join('/');

    return {
        workflowId,
        orchestractoRootPath,
    };
};

export const getCurrentWorkflowRunsPath = ({
    orchestractoRootPath,
    workflowId,
}: {
    orchestractoRootPath: string;
    workflowId: string;
}) => {
    return `${getOrchestractoRunPath(orchestractoRootPath)}/${workflowId}`;
};

export const getCurrentWorkflowRunPath = ({
    workflowId,
    orchestractoRootPath,
    runId,
}: {
    orchestractoRootPath: string;
    workflowId: string;
    runId: string;
}) => {
    return `${getCurrentWorkflowRunsPath({workflowId, orchestractoRootPath})}/${runId}`;
};

export const getCurrentWorkflowOneTimeRunsPath = ({
    orchestractoRootPath,
    runId,
}: {
    orchestractoRootPath: string;
    runId: string;
}) => {
    return `${orchestractoRootPath}/one_time_runs/${runId}`;
};

export const getCurrentWorkflowExecutionStatePath = (args: {
    orchestractoRootPath: string;
    workflowId: string;
    runId: string;
}) => {
    return `${getCurrentWorkflowRunPath(args)}/wfexecstate`;
};

export const getCurrentWorkflowResultPath = (args: {
    orchestractoRootPath: string;
    workflowId: string;
    runId: string;
}) => {
    return `${getCurrentWorkflowRunPath(args)}/result`;
};

export const getCurrentWorkflowRunContent = (args: {
    orchestractoRootPath: string;
    workflowId: string;
    runId: string;
}) => {
    return `${getCurrentWorkflowRunPath(args)}/workflow`;
};

export const getCurrentWorkflowRunConfig = (args: {
    orchestractoRootPath: string;
    workflowId: string;
    runId: string;
}): WorkflowRunConfig => {
    const {orchestractoRootPath, runId, workflowId} = args;

    return {
        workflowExecutionStatePath: getCurrentWorkflowExecutionStatePath({
            orchestractoRootPath,
            runId,
            workflowId,
        }),
        workflowResultPath: getCurrentWorkflowResultPath({orchestractoRootPath, runId, workflowId}),
        workflowRunContentPath: getCurrentWorkflowRunContent({
            orchestractoRootPath,
            runId,
            workflowId,
        }),
    };
};

export const isPersistentWorkflowRunStatus = (status: WorkflowExecutionStatus) => {
    return WORKFLOW_RUN_PERSISTENT_STATUS[status];
};
