import {setupMonacoAutocompleteExtension} from './autocomplete';
import {setupWorkflowJSONValidation} from './workflow-validation';
import type * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

type SetupMonacoJupyterExtensionsPayload = {
    model: monaco.editor.IModel;
};

export const setupMonacoJupyterExtensions = ({model}: SetupMonacoJupyterExtensionsPayload) => {
    setupMonacoAutocompleteExtension({model});
};

export const setupMonacoWorkflowExtensions = () => {
    setupWorkflowJSONValidation();
};
