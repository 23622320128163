import React, {useEffect} from 'react';
import {WorkflowTabComponent} from '../../components/WorkflowTabComponent/WorkflowTabComponent';
import {
    loadWorkflowFromCypress,
    resetWorkflowState,
} from 'features/Orchestracto/store/actions/workflow';
import {useDispatch, useSelector} from 'react-redux';
import {selectIsWorkflowLoaded} from 'features/Orchestracto/store/selectors/workflow';
import {Loader} from '@gravity-ui/uikit';
import {StepDetailsModal} from '../StepDetailsModal/StepDetailsModal';

export const WorkflowTabContainer: React.FC = () => {
    const dispatch = useDispatch();
    const isWorkflowLoaded = useSelector(selectIsWorkflowLoaded);

    useEffect(() => {
        dispatch(loadWorkflowFromCypress());

        return () => {
            dispatch(resetWorkflowState());
        };
    }, []);

    if (!isWorkflowLoaded) {
        return <Loader />;
    }

    return (
        <>
            <WorkflowTabComponent />
            <StepDetailsModal />
        </>
    );
};
