import {SecretsList} from 'components/SecretsList/SecretsList';
import React, {ComponentProps, useState} from 'react';
import './BlueprintAttachedFilesComponent.scss';
import block from 'bem-cn-lite';

const b = block('blueprint-attached-files');

type BlueprintAttachedFilesComponentProps = {
    className?: string;
    isReadonly?: boolean;
};

export const BlueprintAttachedFilesComponent: React.FC<BlueprintAttachedFilesComponentProps> = ({
    className,
    isReadonly,
}) => {
    const [value, setValue] = useState<ComponentProps<typeof SecretsList>['value']>([]);

    return (
        <div className={b(null, className)}>
            <div className="elements-heading elements-heading_size_xs">Attached files</div>
            {isReadonly && !value.length ? <div>No attached files</div> : null}
            <SecretsList isReadonly={isReadonly} value={value} onUpdate={setValue} />
        </div>
    );
};
