import {useNavigationBlocker} from './useNavigationBlocker';
import {useCallback} from 'react';
import {setMode} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation';
import {useDispatch, useSelector} from 'react-redux';
import {getEffectiveMode} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation/navigation';

const TAB_NAVIGATION_PARAM = 'navmode';
const watchSearchParamsList = new Set([TAB_NAVIGATION_PARAM]);

export const useNavigationBlockerWithTabs = (condition: boolean) => {
    const dispatch = useDispatch();
    const currentTabMode = useSelector(getEffectiveMode, () => true);

    const onSearchParamsChange = useCallback(
        ({changedParams, confirmed}: {changedParams: Set<string>; confirmed: boolean}) => {
            if (changedParams.has(TAB_NAVIGATION_PARAM) && !confirmed) {
                dispatch(setMode(currentTabMode));
            }
        },
        [currentTabMode],
    );

    useNavigationBlocker({
        shouldListen: condition,
        watchSearchParamsList,
        onSearchParamsChange,
    });
};
