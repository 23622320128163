import {combineSlices} from '@reduxjs/toolkit';
import {workflowSlice} from './workflow';
import {modals} from './modals';
import {runsSlice} from './runs';

export const orchestractoRoot = combineSlices({
    workflow: workflowSlice.reducer,
    runs: runsSlice.reducer,
    modals,
});
