import {WorkflowDetailsComponent} from 'features/Orchestracto/components/WorkflowDetailsComponent/WorkflowDetailsComponent';
import {selectParsedWorkflow} from 'features/Orchestracto/store/selectors/workflow';
import React from 'react';
import {useSelector} from 'react-redux';

export const WorkflowDetailsContainer: React.FC = () => {
    const parsedWorkflow = useSelector(selectParsedWorkflow);

    if (!parsedWorkflow) {
        return null;
    }

    const {_format_version: version, triggers} = parsedWorkflow;

    return <WorkflowDetailsComponent {...{version, triggers}} />;
};
