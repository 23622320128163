import {combineReducers} from '@reduxjs/toolkit';
import {RootState} from '@ytsaurus-ui-platform/src/ui/store/reducers';
import {jupyterRoot} from '../features/Jupyter/store/slices';
import {navigationRoot} from '../features/Navigation/store/slices';
import {universeResourcesRoot} from 'features/UniverseResources/store/slices';
import {orchestractoRoot} from 'features/Orchestracto/store/slices';
import {tractoRegistryRoot} from 'features/TractoRegistry/store/slices';
import {monitoringRoot} from '../features/Monitoring/store';

export const nebiusAppReducers = {
    nebius: combineReducers({
        jupyter: jupyterRoot,
        navigation: navigationRoot,
        universeResources: universeResourcesRoot,
        orchestracto: orchestractoRoot,
        tractoRegistry: tractoRegistryRoot,
        monitoring: monitoringRoot,
    }),
};

export type NebiusRootState = RootState & {
    nebius: ReturnType<(typeof nebiusAppReducers)['nebius']>;
};
