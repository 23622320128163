import React from 'react';

import {useSelector} from 'react-redux';
import {
    selectCurrentRun,
    selectCurrentRunLoading,
    selectCurrentRunStepsMap,
} from '../../store/selectors/runs';
import {buildGraphFromWorkflowWithStatuses} from '../../utils/graph';
import {Flex, Icon, Loader, Text} from '@gravity-ui/uikit';
import {GraphContainer} from '../GraphContainer/GraphContainer';
import {isPersistentWorkflowRunStatus} from '../../utils/workflow';
import {useNebiusDispatch} from '../../../../store/nebius-dispatch';
import {loadWorkflowRunState, loadWorkflowRunsFormCypress} from '../../store/actions/runs';
import {runsSlice} from '../../store/slices/runs';
import {NodesLeft} from '@gravity-ui/icons';

export const WorkflowExecutionGraphContainer = () => {
    const dispatch = useNebiusDispatch();

    const workflowExecution = useSelector(selectCurrentRun);
    const loading = useSelector(selectCurrentRunLoading);
    const stepsMap = useSelector(selectCurrentRunStepsMap);

    const pollingId = React.useRef<ReturnType<typeof setTimeout> | undefined>();

    React.useEffect(() => {
        if (!workflowExecution) {
            return;
        }

        const {workflowStatus, runId} = workflowExecution;

        function polling() {
            dispatch(loadWorkflowRunsFormCypress());
            dispatch(loadWorkflowRunState({runId}))
                .unwrap()
                .then((currentRun) => {
                    dispatch(runsSlice.actions.setCurrentRun({currentRun}));

                    if (!isPersistentWorkflowRunStatus(currentRun.workflowStatus)) {
                        pollingId.current = setTimeout(polling, 5000);
                    }
                });
        }

        if (!isPersistentWorkflowRunStatus(workflowStatus) && !pollingId.current) {
            polling();
        }
    }, [workflowExecution]);

    const getData = React.useCallback(() => {
        if (!workflowExecution) {
            throw new Error('Cannot show graph without workflow');
        }

        return buildGraphFromWorkflowWithStatuses({
            workflow: workflowExecution.workflow,
            workflowStepsStateMap: workflowExecution.workflowStepsStateMap,
        });
    }, [workflowExecution]);

    if (loading) {
        return (
            <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
                <Loader size="l" />
            </Flex>
        );
    }

    if (!workflowExecution) {
        return (
            <Flex
                alignItems="center"
                justifyContent="center"
                direction="column"
                width="100%"
                height="100%"
                gap="10"
            >
                <Text variant="body-2">Select workflow run from the table</Text>
                <Icon data={NodesLeft} size={60} />
            </Flex>
        );
    }

    return <GraphContainer stepsMap={stepsMap} getData={getData} />;
};
