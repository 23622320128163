import React from 'react';
import block from 'bem-cn-lite';
import './CommandPalette.scss';
import {Select, SelectOption, SelectProps, Text} from '@gravity-ui/uikit';

const b = block('command-palette');

type CommandPaletteProps = {
    open: boolean;
    options: SelectOption[];
    onOpenChange: (open: boolean) => void;
    onUpdate: (value: string[]) => void;
};

const filterOption = (item: SelectOption, filter: string) => {
    const words = filter.toUpperCase().split(' ').filter(Boolean);

    if (!words.length) {
        return true;
    }

    const value = item.value.toUpperCase();

    return words.every((word: string) => value.includes(word));
};

const renderOption = (option: SelectOption) => {
    const {value, text} = option;

    return <Text>{text || value}</Text>;
};

export const CommandPalette = ({open, options, onOpenChange, onUpdate}: CommandPaletteProps) => {
    const selectProps: SelectProps = {
        value: [''],
        filterPlaceholder: 'Type to search commands...',
        options,
        onUpdate,
        filterable: true,
        filterOption,
        open,
        size: 'xl',
        popupWidth: 400,
        onOpenChange,
        renderOption,
    };

    return (
        <div className={b()}>
            <Select {...selectProps} />
        </div>
    );
};
