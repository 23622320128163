import React, {useCallback, useMemo} from 'react';
import block from 'bem-cn-lite';
import {Flex, Icon} from '@gravity-ui/uikit';
import {ArrowDown, ArrowsRotateRight, CirclePlay, CircleStop, LogoPython} from '@gravity-ui/icons';

import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import type {JupytListResponseItem} from 'features/Jupyter/api/jupyt';

import {JupytSelect} from '../JupytSelect/JupytSelect';
import PathActions from '@ytsaurus-ui-platform/src/ui/pages/navigation/content/MapNode/PathActions';
import {JupyterStatusContainer} from 'features/Jupyter/containers/JupyterStatusContainer/JupyterStatusContainer';
import {SaveNotebookButtonContainer} from 'features/Jupyter/containers/SaveNotebookButtonContainer/SaveNotebookButtonContainer';

import './JupytOperationsList.scss';

const b = block('jupyt-operations-list');

type JupytOperationsListProps = {
    isSuspendedInFlight: boolean;
    isCurrentJupytSuspended: boolean;
    isNotebookRunning: boolean;
    operations: JupytListResponseItem[];
    onRunNotebookClick: () => void;
    onStopNotebookClick: () => void;
    onRestartKernelClick: () => void;
    onResumeKernelClick: () => void;
    currentJupytAlias: string;
    onCurrentJupytAliasChange: (value: string) => void;
    jupytOperationsLink: string;
    onEmptyJupytSelectClick: () => void;
    path: string;
    notebookDownloadUrl: string;
    attributes: Record<string, any>;
};

export const JupytOperationsList: React.FC<JupytOperationsListProps> = ({
    isNotebookRunning,
    isSuspendedInFlight,
    isCurrentJupytSuspended,
    operations,
    onRunNotebookClick,
    onStopNotebookClick,
    onRestartKernelClick,
    currentJupytAlias,
    onResumeKernelClick,
    onCurrentJupytAliasChange,
    jupytOperationsLink,
    onEmptyJupytSelectClick,
    path,
    notebookDownloadUrl,
    attributes,
}) => {
    const handleJupytAliasChange = useCallback(
        ([value]: string[]) => {
            onCurrentJupytAliasChange(value);
        },
        [onCurrentJupytAliasChange],
    );

    const notebookDownloadName = useMemo(() => {
        const filename = path.split('/').pop();

        return filename;
    }, [path]);

    const selectifyJupytItem = (item: JupytListResponseItem) => {
        const jupyt = {
            content: item.$value,
            value: item.$value,
            data: {
                state: item.$attributes?.state,
                health: item.$attributes?.health,
                suspended: item.$attributes?.yt_operation_suspended,
            },
        };

        return jupyt;
    };

    const jupyts = operations.map(selectifyJupytItem);

    return (
        <Flex direction="row" gap={3} className={b()}>
            {isCurrentJupytSuspended ? (
                <Button
                    className={b('suspended_button')}
                    onClick={onResumeKernelClick}
                    loading={isSuspendedInFlight}
                >
                    <Icon data={CirclePlay} size={13} />
                    Resume
                </Button>
            ) : (
                <JupyterStatusContainer />
            )}
            <JupytSelect
                jupyts={jupyts}
                onEmptyClick={onEmptyJupytSelectClick}
                onUpdate={handleJupytAliasChange}
                currentJupyt={[currentJupytAlias]}
            />
            <Flex gap={2}>
                <Button href={jupytOperationsLink} target="_blank">
                    <Icon data={LogoPython} size={13} />
                    To kernels
                </Button>
                {currentJupytAlias ? (
                    <Button onClick={onRestartKernelClick}>
                        <Icon data={ArrowsRotateRight} size={13} />
                        Restart session
                    </Button>
                ) : null}
                {isNotebookRunning ? (
                    <Button onClick={onRunNotebookClick}>
                        <Icon data={CirclePlay} size={13} />
                        Run all
                    </Button>
                ) : (
                    <Button onClick={onStopNotebookClick} selected>
                        <Icon data={CircleStop} size={13} />
                        Stop execution
                    </Button>
                )}
                <Button extraProps={{download: notebookDownloadName}} href={notebookDownloadUrl}>
                    <Icon data={ArrowDown} size={13} />
                    Download
                </Button>
                <SaveNotebookButtonContainer />
                <PathActions
                    onlyDropdown={true}
                    dropDownBtnTheme={'outlined'}
                    dropDownBtnSize={'m'}
                    item={{
                        type: 'document',
                        path,
                        dynamic: false,
                        // @ts-ignore
                        // it is used only with table type and can be ignored
                        tabletState: undefined,
                        $attributes: attributes,
                        $value: undefined,
                    }}
                />
            </Flex>
        </Flex>
    );
};
