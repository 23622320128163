import * as React from 'react';
import block from 'bem-cn-lite';
import {Table, TableColumnConfig} from '@gravity-ui/uikit';
import ClipboardButton from '@ytsaurus-ui-platform/src/ui/components/ClipboardButton/ClipboardButton';

import './TractoRegistryImageLayers.scss';

const b = block('tracto-registry-image-layers');

type Col = {
    digest: string;
    size: string;
};

interface TractoRegistryImageLayersProps {
    layers: Col[];
    tractoRegistryUrl: string;
    repository: string;
}

export const TractoRegistryImageLayers: React.FC<TractoRegistryImageLayersProps> = ({
    layers,
    tractoRegistryUrl,
    repository,
}) => {
    const columns: TableColumnConfig<Col>[] = [
        {
            id: 'digest',
            name: 'Digest',
            template(col) {
                return (
                    <span className={b('table-item-digest')}>
                        <span className={b('table-item-digest-value')}>{col.digest}</span>
                        &nbsp;
                        <ClipboardButton
                            view={'flat-secondary'}
                            text={`${tractoRegistryUrl}/${repository}@${col.digest}`}
                        />
                    </span>
                );
            },
        },
        {
            id: 'size',
            name: 'Size',
        },
    ];

    return (
        <div className={b()}>
            <h3>Layers</h3>
            <div>
                <Table data={layers} columns={columns} />
            </div>
        </div>
    );
};
