import React from 'react';

import {useSelector} from 'react-redux';
import {YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {updateView} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation';
import {useNebiusDispatch} from '../../../../store/nebius-dispatch';
import {modalCreateDocumentSlice} from 'features/Navigation/store/slices/modals/modal-create-document';
import {selectModalCreateDocumentState} from 'features/Navigation/store/selectors/modals/modal-create-document';
import {createSecretStoreInCypress} from 'features/SecretStore/store/actions/secrets';

type FormValues = {documentPath: string};

export const SecretStoreCreateDialog = () => {
    const dispatch = useNebiusDispatch();
    const state = useSelector(selectModalCreateDocumentState);
    const path = useSelector(getPath);

    const handleIsApplyDisabled = () => {
        return false;
    };

    return (
        <YTDFDialog<FormValues>
            visible={state.documentType === 'secret-store'}
            headerProps={{title: 'Create secret store'}}
            onAdd={(args) => {
                return dispatch(
                    createSecretStoreInCypress({path: args.getState().values.documentPath}),
                )
                    .then(({payload}) => {
                        return payload
                            ? dispatch(modalCreateDocumentSlice.actions.close())
                            : Promise.reject();
                    })
                    .then(() => dispatch(updateView()));
            }}
            onClose={() => {
                dispatch(modalCreateDocumentSlice.actions.close());
            }}
            fields={[
                {
                    name: 'documentPath',
                    type: 'text',
                    caption: 'Secret store path',
                    tooltip: 'Path to save secret store',
                    initialValue: `${path}/New secret store`,
                },
            ]}
            isApplyDisabled={handleIsApplyDisabled}
        />
    );
};
