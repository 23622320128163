import React from 'react';
import block from 'bem-cn-lite';

import './BlueprintEditorComponent.scss';
import TractoMonacoEditor from 'components/TractoMonacoEditor/TractoMonacoEditor';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {Flex} from '@gravity-ui/uikit';
import {KeyEnum} from 'constants/keys';
import {useMetaKeyPressHook} from 'hooks/useMetaKeyPressHook';

const b = block('blueprint-editor');

type BlueprintEditorComponentProps = {
    className?: string;
    initialValue: string;
    onChange: (value: string) => void;
    onSave: () => void;
    onRun: () => void;
    isBlueprintChanged: boolean;
    isBlueprintRunning: boolean;
};

export const BlueprintEditorComponent: React.FC<BlueprintEditorComponentProps> = ({
    className,
    isBlueprintChanged,
    isBlueprintRunning,
    initialValue,
    onChange,
    onSave,
    onRun,
}) => {
    const isSaveDisabled = !isBlueprintChanged;

    let content = 'Save';

    if (!isBlueprintChanged) {
        content = 'Saved';
    }

    useMetaKeyPressHook((event: KeyboardEvent) => {
        if (event.key === KeyEnum.S) {
            event.preventDefault();

            return onSave();
        }

        if (event.key === KeyEnum.ENTER) {
            event.preventDefault();

            return onRun();
        }
    });

    return (
        <div className={b(null, className)}>
            <div className="elements-heading elements-heading_size_xs">Editor</div>
            <TractoMonacoEditor
                className={b('editor')}
                value={initialValue}
                onChange={onChange}
                language="dockerfile"
                adjustToContent={false}
            />
            <Flex className={b('controls')} gap="2">
                <Button
                    className={b('apply-btn')}
                    view="action"
                    size="l"
                    onClick={onSave}
                    disabled={isSaveDisabled}
                >
                    {content}
                </Button>
                <Button view="action" size="l" onClick={onRun} loading={isBlueprintRunning}>
                    Run
                </Button>
            </Flex>
        </div>
    );
};
