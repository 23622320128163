import React from 'react';

import {useSelector} from 'react-redux';
import {YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {updateView} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation';
import {useNebiusDispatch} from '../../../../store/nebius-dispatch';
import {modalCreateDocumentSlice} from '../../store/slices/modals/modal-create-document';
import {selectModalCreateDocumentState} from '../../store/selectors/modals/modal-create-document';
import {createDocument} from '../../store/actions/document';

type FormValues = {documentPath: string};

export const DocumentCreateDialog = () => {
    const dispatch = useNebiusDispatch();
    const state = useSelector(selectModalCreateDocumentState);
    const path = useSelector(getPath);

    const handleIsApplyDisabled = () => {
        return false;
    };

    return (
        <YTDFDialog<FormValues>
            visible={state.documentType === 'document'}
            headerProps={{title: 'Create document'}}
            onAdd={(args) => {
                return dispatch(createDocument({path: args.getState().values.documentPath}))
                    .then(({payload}) => {
                        return payload
                            ? dispatch(modalCreateDocumentSlice.actions.close())
                            : Promise.reject();
                    })
                    .then(() => dispatch(updateView()));
            }}
            onClose={() => {
                dispatch(modalCreateDocumentSlice.actions.close());
            }}
            fields={[
                {
                    name: 'documentPath',
                    type: 'text',
                    caption: 'Document path',
                    tooltip: 'Path to save document',
                    initialValue: `${path}/New document`,
                },
            ]}
            isApplyDisabled={handleIsApplyDisabled}
        />
    );
};
