import React, {useCallback} from 'react';
import {useNebiusDispatch} from 'store/nebius-dispatch';
import {modalCreateJupytSlice} from 'features/Jupyter/store/slices/modals/modal-create-jupyt';
import {JupytListPageTopRow} from '../../components/JupytListPageTopRow/JupytListPageTopRow';
import {loadJupytPageList} from '../../store/actions/jupyt/jupyt';
import {JupytCreateDialog} from '../Dialogs/JupytCreateDialog/JupytCreateDialog';
import {modalCreateDocumentSlice} from 'features/Navigation/store/slices/modals/modal-create-document';

export const JupytListPageTopRowContainer = () => {
    const dispatch = useNebiusDispatch();

    const handleCreateKernelClick = useCallback(() => {
        dispatch(modalCreateJupytSlice.actions.open());
    }, []);

    const handleCreateNotebookClick = useCallback(() => {
        dispatch(modalCreateDocumentSlice.actions.open({documentType: 'notebook'}));
    }, []);

    const handleJupytCreateDialogApply = React.useCallback(() => {
        return dispatch(loadJupytPageList());
    }, [dispatch]);

    return (
        <>
            <JupytListPageTopRow
                onCreateKernelClick={handleCreateKernelClick}
                onCreateNotebookClick={handleCreateNotebookClick}
            />
            <JupytCreateDialog onApply={handleJupytCreateDialogApply} />
        </>
    );
};
