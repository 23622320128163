import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {WorkflowDocumentType} from 'features/Orchestracto/types/workflow';
import type {editor} from 'monaco-editor';

type WorkflowState = {
    workflow: string;
    initialWorkflow: string;
    markers: editor.IMarker[];
    isWorkflowLoaded: boolean;
    running: boolean;
};

const initialState: WorkflowState = {
    workflow: '',
    initialWorkflow: '',
    markers: [],
    isWorkflowLoaded: false,
    running: false,
};

export const workflowSlice = createSlice({
    name: 'orchestracto.workflow',
    initialState,
    reducers: {
        setWorkflowFromCypress: (
            state,
            action: PayloadAction<{workflow: WorkflowDocumentType}>,
        ) => {
            state.workflow = JSON.stringify(action.payload.workflow, null, 4);
            state.initialWorkflow = state.workflow;
            state.isWorkflowLoaded = true;
        },
        setWorkflow: (state, action: PayloadAction<{workflow: string}>) => {
            state.workflow = action.payload.workflow;
        },
        setMarkers: (state, action: PayloadAction<{markers: editor.IMarker[]}>) => {
            state.markers = action.payload.markers;
        },
        setRunning: (state, action: PayloadAction<{running: boolean}>) => {
            state.running = action.payload.running;
        },
        reset: () => initialState,
    },
});
