import {IOutput, isCode} from '@jupyterlab/nbformat';
import {createSelector} from 'reselect';
import isEqual from 'lodash/isEqual';
import {NebiusRootState} from '../../../../store/reducers';
import {WidgetDisplayData, isWidgetData} from '../../utils/cell';

export const selectNotebookContent = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook.content;

export const selectNotebookCells = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook.content?.cells || [];

export const selectNotebookCellIds = createSelector(
    (state: NebiusRootState) => state.nebius.jupyter.notebook.content?.cells,
    (cells) => (cells || []).map((cell) => cell.metadata.cell_id) as string[],
);

export const selectCell = (state: NebiusRootState, cellId: string) => {
    return state.nebius.jupyter.notebook.content?.cells.find(
        (cell) => cell.metadata.cell_id === cellId,
    );
};

export const selectCellOutputs = (state: NebiusRootState, cellId: string) => {
    const cell = selectCell(state, cellId);

    let outputs: IOutput[] = [];

    if (cell && isCode(cell)) {
        outputs = cell.outputs;
    } else {
        outputs = [];
    }

    return outputs;
};

export const selectRunningCells = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook.runningCells;

export const selectFocusedCellId = (state: NebiusRootState) => {
    return state.nebius.jupyter.notebook.focusedCellId;
};

export const selectFocusedCellIndex = (state: NebiusRootState): number => {
    const focusedCellId = state.nebius.jupyter.notebook.focusedCellId;

    if (!state.nebius.jupyter.notebook.content) {
        return -1;
    }

    return state.nebius.jupyter.notebook.content.cells.findIndex((cell) => {
        return cell.metadata.cell_id === focusedCellId;
    });
};

export const selectFocusedCell = (state: NebiusRootState) => {
    const focusedCellId = state.nebius.jupyter.notebook.focusedCellId;

    return state.nebius.jupyter.notebook.content?.cells.find((cell) => {
        return cell.metadata.cell_id === focusedCellId;
    });
};

export const selectEditableCell = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook.editableCellId;

export const selectCurrentJupytAlias = (state: NebiusRootState) =>
    state.nebius.jupyter.jupyt.currentJupytAlias;

export const checkIfCurrentJupytSuspended = (state: NebiusRootState) => {
    const jupyt = state.nebius.jupyter.jupyt.operations.find(
        (item) => item.$value === state.nebius.jupyter.jupyt.currentJupytAlias,
    );

    if (jupyt) {
        return Boolean(jupyt.$attributes?.yt_operation_suspended);
    }

    return false;
};

export const selectJupyterKernelStatus = (state: NebiusRootState) =>
    state.nebius.jupyter.jupyt.jupyterKernelStatus;

export const selectIsNotebookSaved = (state: NebiusRootState) =>
    isEqual(state.nebius.jupyter.notebook.content, state.nebius.jupyter.notebook.savedContent) ||
    state.nebius.jupyter.notebook.writePermission === 'deny';

export const selectIsSavingInProgress = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook.isSavingInProgress;

export const selectIsUserHasWritePermission = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook.writePermission === 'allow';

export const getNotebookCypressId = (state: NebiusRootState) => {
    const notebookCypressId = state.nebius.jupyter.notebook?.content?.metadata?.notebook_cypress_id;

    if (notebookCypressId) {
        return String(notebookCypressId);
    }

    return null;
};

export const selectDirtyCells = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook.dirtyCells;

export const selectIsSolutionNotebook = (state: NebiusRootState) =>
    state.nebius.jupyter.notebook?.content?.metadata?.is_solution_notebook;

export const doesNotebookHaveWidget = (state: NebiusRootState) =>
    Boolean(
        state.nebius.jupyter.notebook?.content?.cells.some((item) => {
            if (Array.isArray(item?.outputs)) {
                return item?.outputs.some((output) => {
                    if (output && typeof output === 'object' && 'data' in output) {
                        return isWidgetData(output as WidgetDisplayData);
                    }

                    return false;
                });
            }

            return false;
        }),
    );
