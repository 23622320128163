import {SecretListItem, SecretsList} from 'components/SecretsList/SecretsList';
import React, {ComponentProps} from 'react';
import {Flex, Loader} from '@gravity-ui/uikit';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import block from 'bem-cn-lite';
import './SecretStoreTabComponent.scss';

const b = block('tracto-secret-store-tab');

type SecretStoreTabComponentProps = {
    value: SecretListItem[];
    onUpdate: (value: SecretListItem[]) => void;
    loading: boolean;
    onSaveClick: () => void;
};

export const SecretStoreTabComponent: React.FC<SecretStoreTabComponentProps> = ({
    value,
    onUpdate,
    loading,
    onSaveClick,
}) => {
    if (loading) {
        return (
            <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
                <Loader size="l" />
            </Flex>
        );
    }

    const secretsListProps: ComponentProps<typeof SecretsList> = {
        value,
        onUpdate,
    };

    return (
        <div className={b()}>
            <SecretsList {...secretsListProps} />
            <Button
                className={b('save-secret-button', {'no-secrets': !value.length})}
                view="action"
                width="max"
                onClick={onSaveClick}
            >
                Save
            </Button>
        </div>
    );
};
