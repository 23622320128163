import React from 'react';
import {ytApiV3} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import {NebiusThunkDispatch} from 'store/nebius-dispatch';
import {NebiusRootState} from 'store/reducers';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {workflowSlice} from '../slices/workflow';
import {createNebiusAsyncThunk} from '../../../../store/nebius-async-thunk';
import {showErrorInModal} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation/modals/path-editing-popup';
import {
    getCurrentWorkflowLocationConfig,
    getCurrentWorkflowOneTimeRunsPath,
    getCurrentWorkflowRunsPath,
    getDefaultWorkflowContent,
    getWorkflowRunFileContent,
} from '../../utils/workflow';
import {wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import {selectParsedWorkflow} from '../selectors/workflow';
import Link from '@ytsaurus-ui-platform/src/ui/components/Link/Link';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global/index';
import {Page} from '@ytsaurus-ui-platform/src/shared/constants/settings';

export const loadWorkflowFromCypress = () => {
    return async (dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        const path = getPath(getState());

        const workflow = await ytApiV3.get({path});

        dispatch(workflowSlice.actions.setWorkflowFromCypress({workflow}));
    };
};

export const saveWorkflowInCypress = () => {
    return async (dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        const path = getPath(getState());
        const workflow = selectParsedWorkflow(getState());

        if (workflow) {
            await wrapApiPromiseByToaster(ytApiV3.set({path}, workflow), {
                toasterName: 'save-workflow-in-cypress',
                skipSuccessToast: true,
                errorTitle: 'Failed to save workflow',
                errorContent: (error) => {
                    const code = error.code ? `[code ${error.code}]` : '';
                    const message = error.message;

                    return [code, message].filter(Boolean).join(' ');
                },
            });
            await dispatch(loadWorkflowFromCypress());
        }
    };
};

export const createWorkflow = createNebiusAsyncThunk<boolean, {workflowId: string}>(
    'orchestracto.actions.create-workflow',
    async ({workflowId}, thunkAPI) => {
        const path = getPath(thunkAPI.getState());

        const workflowPath = `${path}/${workflowId}`;

        try {
            await ytApiV3.create({
                parameters: {
                    path: workflowPath,
                    type: 'document',
                    attributes: {
                        is_workflow: true,
                        value: getDefaultWorkflowContent(),
                    },
                },
            });
        } catch (error) {
            const action = showErrorInModal(error);

            thunkAPI.dispatch(action);

            return thunkAPI.rejectWithValue(false);
        }

        return true;
    },
);

export const oneTimeRunWorkflow = createNebiusAsyncThunk<void, undefined>(
    'orchestracto.actions.one-time-run-workflow',
    (_, thunkAPI) => {
        const workflowContent = selectParsedWorkflow(thunkAPI.getState());

        if (!workflowContent) {
            return;
        }

        const path = getPath(thunkAPI.getState());
        const cluster = getCluster(thunkAPI.getState());

        const POLLING_TIMEOUT = 1000;
        const POLLING_ATTEMPTS = 40;

        const {orchestractoRootPath, workflowId} = getCurrentWorkflowLocationConfig(path);

        // unique id for orchestracto running operation
        const runId = crypto.randomUUID();

        // we assume that one_time_runs folder always exists since it was created by orchestracto initialization
        // it's always located on the same level as "workflows" directory
        const oneTimeRunFilePath = getCurrentWorkflowOneTimeRunsPath({orchestractoRootPath, runId});

        const run = async () => {
            await ytApiV3.create({
                parameters: {
                    path: oneTimeRunFilePath,
                    type: 'document',
                    attributes: {
                        value: getWorkflowRunFileContent(workflowId, workflowContent),
                    },
                },
            });

            const runPath = getCurrentWorkflowRunsPath({orchestractoRootPath, workflowId});

            for (let i = 0; i < POLLING_ATTEMPTS; i++) {
                const workflowRunStatus = await ytApiV3
                    .get({
                        parameters: {
                            path: `${runPath}/${runId}/@orc_result_status`,
                        },
                    })
                    .catch(() => {});

                if (workflowRunStatus) {
                    return;
                }

                await new Promise((resolve) => setTimeout(resolve, POLLING_TIMEOUT));
            }

            throw new Error("Can't start one time run");
        };

        return wrapApiPromiseByToaster(run(), {
            toasterName: 'one-time-run-workflow',
            successTitle: 'Workflow was started',
            successContent: () => {
                const searchParams = new URLSearchParams();

                searchParams.set('path', path);
                searchParams.set('navmode', 'extra_workflow_runs');
                searchParams.set('runId', runId);

                const url = `/${cluster}/${Page.NAVIGATION}?${searchParams.toString()}`;

                return <Link url={url}>Please visit runs tab for more details</Link>;
            },
            errorTitle: 'Failed to run workflow',
            errorContent: (error) => {
                const code = error.code ? `[code ${error.code}]` : '';
                const message = error.message;

                return [code, message].filter(Boolean).join(' ');
            },
        });
    },
);

export const resetWorkflowState = createNebiusAsyncThunk(
    'orchestracto.actions.reset-workflow-state',
    (_, thunkAPI) => {
        thunkAPI.dispatch(workflowSlice.actions.reset());
    },
);
