import * as React from 'react';
import block from 'bem-cn-lite';

import './TractoRegistryDockerfile.scss';

const b = block('tracto-registry-dockerfile');

interface TractoRegistryDockerfileProps {
    dockerfile: {value: string; content: string}[];
}

export const TractoRegistryDockerfile: React.FC<TractoRegistryDockerfileProps> = ({dockerfile}) => {
    return (
        <div className={b()}>
            <pre>
                <code>
                    {dockerfile.map((item) => {
                        return (
                            <div className={b('row')}>
                                <span className={b('instruction')}>{item.value}</span>&nbsp;
                                {item.content}
                            </div>
                        );
                    })}
                </code>
            </pre>
        </div>
    );
};
