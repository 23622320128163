import React, {ComponentProps, useCallback} from 'react';
import {BlueprintEditorComponent} from 'features/ImageBuilder/components/BlueprintEditorComponent/BlueprintEditorComponent';

const dockerfileMock = `
FROM node:20-alpine
WORKDIR /app
COPY . .
RUN yarn install --production
CMD ["node", "./src/index.js"]
`.trim();

type BlueprintEditorContainerProps = Pick<
    ComponentProps<typeof BlueprintEditorComponent>,
    'className'
>;

export const BlueprintEditorContainer: React.FC<BlueprintEditorContainerProps> = (ownProps) => {
    const initialValue = dockerfileMock;

    const onChange = useCallback(() => {}, []);

    const onSave = useCallback(() => {}, []);

    const onRun = useCallback(() => {}, []);

    const isBlueprintChanged = true;

    const isBlueprintRunning = false;

    const props: ComponentProps<typeof BlueprintEditorComponent> = {
        initialValue,
        onChange,
        onSave,
        onRun,
        isBlueprintChanged,
        isBlueprintRunning,
        ...ownProps,
    };

    return <BlueprintEditorComponent {...props} />;
};
