import {ContentsManager, ServerConnection} from '@jupyterlab/services';
import type * as nbformat from '@jupyterlab/nbformat';
import {Toaster} from '@gravity-ui/uikit';
import {showErrorPopup, wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import {
    generateNotebookCypressId,
    getJupyterContentManagerPath,
    getParentFolderPath,
} from 'features/Jupyter/utils/notebook';
import {SYSTEM_JUPYT_ALIAS} from '../../../../../shared/constants/jupyter/jupyter-api';
import {
    getHttpRequestInit,
    getJupyterBaseUrl,
} from '../../../../../shared/utils/jupyter/jupyter-api';
// @ts-ignore
import yt from '@ytsaurus/javascript-wrapper/lib/yt';

function createContentsManager({cluster, path}: {cluster: string; path: string}) {
    const serverSettings = ServerConnection.makeSettings({
        baseUrl: getJupyterBaseUrl({cluster, alias: SYSTEM_JUPYT_ALIAS}),
        init: getHttpRequestInit({path}),
    });

    return new ContentsManager({serverSettings: serverSettings});
}

type CreateNotebookPayload = {cluster: string; path: string};

export async function createNotebookInCypress({cluster, path}: CreateNotebookPayload) {
    const parentFolderPath = getParentFolderPath(path);

    let hasNode = true;

    try {
        await getNotebookContentFromCypress({cluster, path});
    } catch (error: any) {
        if ('response' in error && error.response.status === 404) {
            hasNode = false;
        }
    }

    try {
        const isParentFolderExist = await yt.v3.exists({
            parameters: {
                path: parentFolderPath,
            },
        });

        if (!isParentFolderExist) {
            await yt.v3.create({
                parameters: {
                    path: parentFolderPath,
                    recursive: true,
                    type: 'map_node',
                },
            });
        }
    } catch {}

    const toaster = new Toaster();
    if (hasNode) {
        const errorMessage = `Node ${path} already exists`;
        toaster.add({
            theme: 'danger',
            name: 'NodeAlreadyExists',
            title: 'Failed to create notebook',
            content: errorMessage,
            autoHiding: 10000,
            actions: [
                {
                    label: ' view',
                    onClick: () => showErrorPopup({message: errorMessage}),
                },
            ],
        });

        return Promise.reject(errorMessage);
    }

    const manager = createContentsManager({cluster, path});

    const promise = manager.save(getJupyterContentManagerPath(path), {
        type: 'notebook',
        format: 'json',
        content: {
            nbformat: 4,
            nbformat_minor: 5,
            metadata: {
                notebook_cypress_id: generateNotebookCypressId(),
            },
            cells: [],
        },
    });

    promise.finally(() => {
        manager.dispose();
    });

    return wrapApiPromiseByToaster(promise, {
        skipSuccessToast: true,
        toasterName: 'SaveNotebookError',
        errorTitle: 'Failed to create notebook',
        autoHide: true,
    });
}

type SaveNotebookPayload = {cluster: string; path: string; content: nbformat.INotebookContent};

export async function saveNotebookInCypress({cluster, content, path}: SaveNotebookPayload) {
    const manager = createContentsManager({cluster, path});

    const promise = manager
        .save(getJupyterContentManagerPath(path), {
            content,
            format: 'json',
            type: 'notebook',
        })
        .finally(() => {
            manager.dispose();
        });

    await wrapApiPromiseByToaster(promise, {
        toasterName: 'save-notebook-in-cypress',
        skipSuccessToast: true,
        errorTitle: 'Failed to save notebook',
    });
}

type GetNotebookPayload = {
    cluster: string;
    path: string;
};

export function getNotebookContentFromCypress({cluster, path}: GetNotebookPayload) {
    const manager = createContentsManager({cluster, path});

    const promise = manager.get(getJupyterContentManagerPath(path));

    promise.finally(() => {
        manager.dispose();
    });

    return promise;
}

type GetDownloadUrlPayload = {
    cluster: string;
    path: string;
};

export function getNotebookDownloadUrl({cluster, path}: GetDownloadUrlPayload) {
    const manager = createContentsManager({cluster, path});
    const promise = manager.getDownloadUrl(getJupyterContentManagerPath(path));
    promise.finally(() => {
        manager.dispose();
    });
    return promise;
}
