import React from 'react';
import FlexSplitPane from '@ytsaurus-ui-platform/src/ui/components/FlexSplitPane/FlexSplitPane';
import block from 'bem-cn-lite';
import './BlueprintRunsTabComponent.scss';
import {BlueprintRunsListContainer} from 'features/ImageBuilder/containers/BlueprintRunsListContainer/BlueprintRunsListContainer';
import {BlueprintRunDetailsContainer} from 'features/ImageBuilder/containers/BlueprintRunDetailsContainer/BlueprintRunDetailsContainer';

const b = block('blueprint-runs');

export const BlueprintRunsTabComponent: React.FC = () => {
    return (
        <FlexSplitPane className={b('split-pane')} direction={FlexSplitPane.HORIZONTAL}>
            <BlueprintRunsListContainer />
            <BlueprintRunDetailsContainer />
        </FlexSplitPane>
    );
};
