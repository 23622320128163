import React, {useRef} from 'react';
import {useHistory} from 'react-router';

const ACTIONS = ['POP', 'PUSH'];

// Safari doesn't support requestIdleCallback, so fe use fallback to setTimeout here.
// Details: https://caniuse.com/?search=requestIdleCallback
const requestIdleCallbackWithFallback = window.requestIdleCallback || setTimeout;

function compareObservedParams(
    current: URLSearchParams,
    previous: URLSearchParams,
    paramsList: Set<string>,
) {
    const changed = new Set<string>();

    const iterator = paramsList.values();

    for (const param of iterator) {
        const currentValue = current.get(param);
        const previousValue = previous.get(param);

        if (currentValue !== previousValue) {
            changed.add(param);
        }
    }

    return changed;
}

export const useNavigationBlocker = ({
    shouldListen,
    watchSearchParamsList,
    onSearchParamsChange,
}: {
    shouldListen: boolean;
    watchSearchParamsList?: Set<string>;
    onSearchParamsChange?: (args: {changedParams: Set<string>; confirmed: boolean}) => void;
}) => {
    const history = useHistory();
    const flag = useRef(true);
    const locationMap = useRef(new Set());
    const observedSearchParams = useRef(watchSearchParamsList ?? new Set<string>());

    React.useEffect(() => {
        flag.current = true;
        locationMap.current = new Set();

        // @ts-expect-error
        const unblock = history.block((location, action) => {
            const currentLocation = location;
            const previousLocation = history.location;

            const currentSearch = currentLocation.search;
            const prevousSearch = previousLocation.search.replace('?', '');

            const fullPath = `${currentLocation.pathname}${currentSearch.search}`;

            requestIdleCallbackWithFallback(() => {
                locationMap.current = new Set();
            });

            if (shouldListen && ACTIONS.includes(action)) {
                const changedParams = compareObservedParams(
                    new URLSearchParams(currentSearch),
                    new URLSearchParams(prevousSearch),
                    observedSearchParams.current,
                );

                const isObservedParamsChanged = changedParams.size !== 0;

                if (
                    currentLocation.pathname === previousLocation.pathname &&
                    currentSearch.includes(prevousSearch) &&
                    !isObservedParamsChanged
                ) {
                    return false;
                }

                if (locationMap.current.has(fullPath)) {
                    return false;
                }

                locationMap.current.add(fullPath);

                const confirmed = window.confirm('Changes you made may not be saved.');

                if (isObservedParamsChanged && onSearchParamsChange) {
                    onSearchParamsChange({changedParams, confirmed});
                }

                return confirmed;
            }

            return true;
        });

        return () => {
            unblock();
        };
    }, [shouldListen, history, onSearchParamsChange]);
};
