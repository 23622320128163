import React from 'react';
import cn from 'bem-cn-lite';

import {Column} from '@gravity-ui/react-data-table';

import format from '@ytsaurus-ui-platform/src/ui/common/hammer/format';
import Link from '@ytsaurus-ui-platform/src/ui/components/Link/Link';
import DataTableYT, {
    DATA_TABLE_YT_SETTINGS_UNDER_TOOLBAR,
} from '@ytsaurus-ui-platform/src/ui/components/DataTableYT/DataTableYT';
import ColumnHeader, {
    ColumnHeaderProps,
} from '@ytsaurus-ui-platform/src/ui/components/ColumnHeader/ColumnHeader';
import Icon from '@ytsaurus-ui-platform/src/ui/components/Icon/Icon';
import Label from '@ytsaurus-ui-platform/src/ui/components/Label/Label';
import {Secondary} from '@ytsaurus-ui-platform/src/ui/components/Text/Text';
import {Tooltip} from '@ytsaurus-ui-platform/src/ui/components/Tooltip/Tooltip';
import {OperationId} from '@ytsaurus-ui-platform/src/ui/components/OperationId/OperationId';
import {SubjectCard} from '@ytsaurus-ui-platform/src/ui/components/SubjectLink/SubjectLink';
import {OperationPool} from '@ytsaurus-ui-platform/src/ui/components/OperationPool/OperationPool';
import ClipboardButton from '@ytsaurus-ui-platform/src/ui/components/ClipboardButton/ClipboardButton';

import type {ColumnSortState} from '../types';
import {JupytListTableItem} from '../../../api/jupyt';
import {JupytState} from '../../JupytState/JupytState';
import {JUPYT_OPERATIONS_PAGE_ID} from '../../../constants';
import {JUPYTER_OPERATIONS_TABLE_TITLES} from '../constants';
import {JupytListTableActions} from './JupytListTableActions/JupytListTableActions';
import {KernelPageQa} from '../../../../../../shared/qa';

import './JupytListTable.scss';

const block = cn('jupyter-list-table');

function useJupytListColumns(opts: {
    cluster: string;
    checkedColumns: Array<keyof JupytListTableItem>;
    onSort: ColumnHeaderProps<keyof JupytListTableItem>['onSort'];
    sortState: ColumnSortState;
    onRemoveRowClick: RowAction;
    onStartRowClick: RowAction;
    onStopRowClick: RowAction;
    onResumeClick: RowAction;
}) {
    const columns: Array<Column<JupytListTableItem>> = React.useMemo(() => {
        const columnsByName = {
            pool: {
                name: 'pool',
                header: (
                    <JupytListHeader
                        column="pool"
                        sortState={opts.sortState}
                        onSort={opts.onSort}
                    />
                ),
                render({row}) {
                    return !row.pool ? (
                        format.NO_VALUE
                    ) : (
                        <OperationPool
                            className={block('pool')}
                            cluster={opts.cluster}
                            pool={{pool: row.pool, tree: 'physical'}}
                        />
                    );
                },
                width: 320,
            } as Column<JupytListTableItem>,
            cpu: {
                name: 'cores',
                header: (
                    <JupytListHeader column="cpu" sortState={opts.sortState} onSort={opts.onSort} />
                ),
                render({row}) {
                    return (
                        <span className={block('one-row-cell')}>
                            {row.cpu === undefined ? format.NO_VALUE : format.Number(row.cpu)}
                        </span>
                    );
                },
                align: 'right',
                width: 100,
            } as Column<JupytListTableItem>,
            memory: {
                name: 'memory',
                header: (
                    <JupytListHeader
                        column="memory"
                        sortState={opts.sortState}
                        onSort={opts.onSort}
                    />
                ),
                render({row}) {
                    /* eslint-disable new-cap */
                    return (
                        <span className={block('one-row-cell')}>
                            {row.memory === undefined ? format.NO_VALUE : format.Bytes(row.memory)}
                        </span>
                    );
                    /* eslint-enable new-cap */
                },
                align: 'right',
                width: 120,
            } as Column<JupytListTableItem>,
            health: {
                name: 'health',
                header: (
                    <JupytListHeader
                        column="health"
                        sortState={opts.sortState}
                        onSort={opts.onSort}
                    />
                ),
                render({row}) {
                    return (
                        <Tooltip
                            className={block('health', block('one-row-cell'))}
                            /* eslint-disable-next-line new-cap */
                            content={format.ReadableField(row.health_reason)}
                        >
                            <JupytState capitalize state={row.health} />
                            {row.health_reason && (
                                <Secondary>
                                    &nbsp;
                                    <Icon
                                        className={block('warn-icon')}
                                        awesome={'exclamation-triangle'}
                                    />
                                </Secondary>
                            )}
                        </Tooltip>
                    );
                },
                width: 120,
            } as Column<JupytListTableItem>,
            creation_time: {
                name: 'creation_time',
                header: (
                    <JupytListHeader
                        onSort={opts.onSort}
                        column="creation_time"
                        sortState={opts.sortState}
                        withUndefined
                    />
                ),
                render({row}) {
                    return <DateTimeCell value={row.creation_time} />;
                },
                width: 120,
            } as Column<JupytListTableItem>,
            speclet_modification_time: {
                name: 'speclet_modification_time',
                header: (
                    <JupytListHeader
                        onSort={opts.onSort}
                        column="speclet_modification_time"
                        sortState={opts.sortState}
                    />
                ),
                render({row}) {
                    return <DateTimeCell value={row.speclet_modification_time} />;
                },
                width: 120,
            } as Column<JupytListTableItem>,
            stage: {
                name: 'stage',
                header: (
                    <JupytListHeader
                        column="stage"
                        sortState={opts.sortState}
                        onSort={opts.onSort}
                    />
                ),
                render({row}) {
                    return (
                        <span className={block('one-row-cell')}>{<Label text={row.stage} />}</span>
                    );
                },
                width: 120,
            } as Column<JupytListTableItem>,
            strawberry_state_modification_time: {
                name: 'strawberry_state_modification_time',
                header: (
                    <JupytListHeader
                        onSort={opts.onSort}
                        column="strawberry_state_modification_time"
                        sortState={opts.sortState}
                    />
                ),
                render({row}) {
                    return <DateTimeCell value={row.strawberry_state_modification_time} />;
                },
                width: 120,
            } as Column<JupytListTableItem>,
            yt_operation_finish_time: {
                name: 'yt_operation_finish_time',
                header: (
                    <JupytListHeader
                        onSort={opts.onSort}
                        column="yt_operation_finish_time"
                        sortState={opts.sortState}
                        withUndefined
                    />
                ),
                render({row}) {
                    return <DateTimeCell value={row.yt_operation_finish_time} />;
                },
                width: 120,
            } as Column<JupytListTableItem>,
            yt_operation_start_time: {
                name: 'yt_operation_start_time',
                header: (
                    <JupytListHeader
                        onSort={opts.onSort}
                        column="yt_operation_start_time"
                        sortState={opts.sortState}
                        withUndefined
                    />
                ),
                render({row}) {
                    return <DateTimeCell value={row.yt_operation_start_time} />;
                },
                width: 120,
            } as Column<JupytListTableItem>,
            creator: {
                name: 'creator',
                header: (
                    <JupytListHeader
                        column="creator"
                        sortState={opts.sortState}
                        onSort={opts.onSort}
                    />
                ),
                render({row}) {
                    return (
                        <div className={block('one-row-cell', block('creator'))}>
                            {!row.creator ? (
                                format.NO_VALUE
                            ) : (
                                <SubjectCard className={block('full-width')} name={row.creator} />
                            )}
                        </div>
                    );
                },
                width: 120,
            } as Column<JupytListTableItem>,
            state: {
                name: 'state',
                header: (
                    <JupytListHeader
                        column="state"
                        sortState={opts.sortState}
                        onSort={opts.onSort}
                    />
                ),
                render({row}) {
                    return (
                        <span className={block('one-row-cell')}>
                            <JupytState
                                capitalize
                                state={row.state}
                                suspended={row.yt_operation_suspended}
                            />
                        </span>
                    );
                },
                width: 120,
            } as Column<JupytListTableItem>,
            docker_image: {
                name: 'docker_image',
                header: (
                    <JupytListHeader
                        column="docker_image"
                        sortState={opts.sortState}
                        onSort={opts.onSort}
                    />
                ),
                render({row}) {
                    return (
                        <div>
                            {row.docker_image}
                            <ClipboardButton
                                text={row.docker_image}
                                view="clear"
                                visibleOnRowHover
                                inlineMargins
                            />
                        </div>
                    );
                },
            } as Column<JupytListTableItem>,
        } as Record<keyof JupytListTableItem, Column<JupytListTableItem>>;

        const res = opts.checkedColumns.map((i) => columnsByName[i]).filter(Boolean);

        return [
            {
                name: 'alias',
                header: (
                    <JupytListHeader
                        column="alias"
                        sortState={opts.sortState}
                        onSort={opts.onSort}
                    />
                ),
                render({row}) {
                    return (
                        <div>
                            <Link
                                url={`/${opts.cluster}/${JUPYT_OPERATIONS_PAGE_ID}/${row.alias}`}
                                theme="primary"
                                routed
                            >
                                {row.alias}
                            </Link>
                            <ClipboardButton
                                text={row.alias}
                                view="clear"
                                visibleOnRowHover
                                inlineMargins
                            />
                            <div>
                                <OperationId
                                    id={row.yt_operation_id}
                                    cluster={opts.cluster}
                                    color="secondary"
                                    allowCopy
                                />
                            </div>
                        </div>
                    );
                },
            } as Column<JupytListTableItem>,
            ...res,
            {
                name: 'actions',
                header: '',
                render({row}) {
                    return (
                        <span className={block('one-row-cell')}>
                            <JupytListTableActions
                                state={row.state}
                                isSuspended={row.yt_operation_suspended}
                                onRemoveClick={() => opts.onRemoveRowClick({alias: row.alias})}
                                onStartClick={() => opts.onStartRowClick({alias: row.alias})}
                                onStopClick={() => opts.onStopRowClick({alias: row.alias})}
                                onResumeClick={() => opts.onResumeClick({alias: row.alias})}
                            />
                        </span>
                    );
                },
                align: 'center',
                width: 60,
            } as Column<JupytListTableItem>,
        ];
    }, [opts.cluster, opts.checkedColumns]);

    return columns;
}

function JupytListHeader({
    column,
    onSort,
    sortState,
    withUndefined,
}: {
    column: keyof JupytListTableItem;
    withUndefined?: boolean;
    onSort?: ColumnHeaderProps<keyof JupytListTableItem>['onSort'];
    sortState: ColumnSortState;
}) {
    return (
        <ColumnHeader<typeof column>
            allowUnordered
            withUndefined={withUndefined}
            column={column}
            /* eslint-disable-next-line new-cap */
            title={JUPYTER_OPERATIONS_TABLE_TITLES[column] ?? format.ReadableField(column)}
            {...sortState[column]}
            onSort={(col, nextOrder, options) => {
                onSort?.(col, nextOrder, options);
            }}
        />
    );
}

function DateTimeCell({value}: {value?: string}) {
    if (!value) {
        return <span className={block('one-row-cell')}>{format.NO_VALUE}</span>;
    }

    // eslint-disable-next-line new-cap
    const time: string = format.DateTime(value);
    const lastSpace = time.lastIndexOf(' ');
    return (
        <React.Fragment>
            <span>{time.substring(0, lastSpace)}</span>
            <div>{time.substring(lastSpace + 1)}</div>
        </React.Fragment>
    );
}

type RowAction = (opts: {alias: string}) => void;

function JupytListTable(props: {
    cluster: string;
    checkedColumns: Array<keyof JupytListTableItem>;
    items: JupytListTableItem[];
    onSort: ColumnHeaderProps<keyof JupytListTableItem>['onSort'];
    sortState: ColumnSortState;
    onRemoveRowClick: RowAction;
    onStartRowClick: RowAction;
    onStopRowClick: RowAction;
    onResumeClick: RowAction;
}) {
    const columns = useJupytListColumns({
        onSort: props.onSort,
        cluster: props.cluster,
        sortState: props.sortState,
        checkedColumns: props.checkedColumns,
        onRemoveRowClick: props.onRemoveRowClick,
        onStartRowClick: props.onStartRowClick,
        onStopRowClick: props.onStopRowClick,
        onResumeClick: props.onResumeClick,
    });

    return (
        <div className={block()} data-qa={KernelPageQa.KernelsList}>
            <DataTableYT
                useThemeYT
                className={block()}
                settings={DATA_TABLE_YT_SETTINGS_UNDER_TOOLBAR}
                columns={columns}
                data={props.items}
            />
        </div>
    );
}

export default React.memo(JupytListTable);
