import axios, {CancelToken} from 'axios';
import {YTError} from '@ytsaurus-ui-platform/src/@types/types';
import {OptionsGroup} from '@ytsaurus-ui-platform/src/ui/components/Dialog/df-dialog-utils';
import {wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import {JupytSecretsMapType} from '../../../../shared/types/jupyt/secrets';

export type WithResult<T> = {result: T};

export type JupytListAttributes = keyof Required<JupytListResponseItem>['$attributes'];

export type JupytDescribeOptionsType = Array<OptionsGroup>;

export type JupytApiListFilters = {creator?: string};

export type JupytApi =
    | {
          action: 'list';
          params: {attributes?: Array<JupytListAttributes>; filters?: JupytApiListFilters};
          response: JupytListResponse;
      }
    | {action: 'resume'; params: {alias: string}; response: void}
    | {action: 'start'; params: {alias: string; untracked?: boolean}; response: void}
    | {action: 'stop'; params: {alias: string}; response: void}
    | {action: 'remove'; params: {alias: string}; response: void}
    | {
          action: 'create';
          params: {
              alias: string;
              speclet_options: {
                  active: boolean;
                  pool?: string;
                  pool_trees?: string[];
                  jupyter_docker_image: string;
              };
              secrets?: JupytSecretsMapType;
          };
          response: void;
      }
    | {
          action: 'edit_options';
          params: {
              alias: string;
              options_to_set: Partial<JupytListResponseItem['$attributes']>;
              options_to_remove: Array<JupytListAttributes>;
          };
          response: void;
      }
    | {
          action: 'describe_options';
          params: {alias: string};
          response: WithResult<JupytDescribeOptionsType>;
      }
    | {action: 'get_speclet'; params: {alias: string}; response: WithResult<unknown>}
    | {
          action: 'get_brief_info';
          params: {alias: string};
          response: WithResult<JupytStatusResponse>;
      }
    | {
          action: 'get_endpoint';
          params: {alias: string};
          response: WithResult<{Address: string}>;
      }
    | {
          action: 'get_secrets';
          params: {alias: string};
          response: WithResult<JupytSecretsMapType | null>;
      }
    | {
          action: 'set_secrets';
          params: {alias: string; secrets: JupytSecretsMapType};
          response: void;
      };

export type JupytStatusResponse = {
    ctl_attributes: {
        cpu?: number;
        memory?: number;
    };
    yt_operation: {
        id?: string;
        state?: string;
        start_time?: string;
        finish_time?: string;
        suspended?: boolean;
    };
    state?: JupytServerStateType;
    health?: JupytServerHealthType;
    health_reason?: unknown;
    incarnation_index?: number;
    creator?: string;
    speclet_modification_time?: string;
    strawberry_state_modification_time?: string;
    stage?: string;
    cpu?: string;
    memory?: string;
    docker_image?: string;

    pool?: string;
    error?: YTError;
};

export type JupytListResponse = WithResult<Array<JupytListResponseItem>>;

export type JupytListResponseItem = {
    $value: string;
    $attributes?: {
        pool?: string;
        creator?: string;
        creation_time?: string;
        health?: JupytServerHealthType;
        health_reason?: string;
        speclet_modification_time?: string;
        stage?: string;
        state?: JupytServerStateType;
        strawberry_state_modification_time?: string;
        cpu?: number;
        memory?: number;
        idle_timeout?: number;
        docker_image?: string;
        yt_operation_id?: string;
        yt_operation_finish_time?: string;
        yt_operation_start_time?: string;
        yt_operation_suspended?: boolean;
    };
};

export type JupytListTableItem = JupytListResponseItem['$attributes'] & {alias: string};

export type JupytServerHealthType = 'good' | 'pending' | 'failed';
export type JupytServerStateType = 'active' | 'inactive' | 'untracked';

export function jupytApiAction<
    T extends JupytApi['action'] = never,
    ApiItem extends JupytApi & {action: T} = JupytApi & {action: T},
>(
    action: T,
    cluster: string,
    params: ApiItem['params'],
    {
        cancelToken,
        skipErrorToast,
        successTitle,
        isAdmin,
    }: {
        cancelToken?: CancelToken;
        skipErrorToast?: boolean;
        successTitle?: string;
        isAdmin?: boolean;
    } = {},
) {
    const query = isAdmin ? '?isDeveloper=true' : '';
    return wrapApiPromiseByToaster(
        axios.request<ApiItem['response']>({
            method: 'POST',
            url: `/proxy/api/jupyt/${cluster}/${action}${query}`,
            data: {
                params: {...params},
            },
            cancelToken,
        }),
        {
            toasterName: `jupyt-${action}`,
            skipSuccessToast: !successTitle,
            successTitle,
            skipErrorToast,
            errorTitle: `'${action}' action failed`,
        },
    ).then((response) => {
        return response.data;
    });
}
