import React, {useCallback} from 'react';
import {JupyterGrid} from '../../components/JupyterGrid/JupyterGrid';
import {JupyterCellContainer} from '../JupyterCellContainer/JupyterCellContainer';
import {AddJupyterCellContainer} from '../AddJupyterCellContainer/AddJupyterCellContainer';
import {AddJupyterCellButtonContainer} from '../AddJupyterCellButtonContainer/AddJupyterCellButtonContainer';
import {NotebookPlaceholderContainer} from '../NotebookPlaceholderContainer/NotebookPlaceholderContainer';
import isEqual from 'lodash/isEqual';
import {SortEndHandler} from 'react-sortable-hoc';
import {useDispatch, useSelector} from 'react-redux';
import {notebookSlice} from 'features/Jupyter/store/slices/notebook';
import {selectNotebookCellIds} from '../../store/selectors/notebook';
import {KeyEnum} from 'constants/keys';

const useJupyterGrid = () => {
    const dispatch = useDispatch();

    const cellIds = useSelector(selectNotebookCellIds, (prevState, newState) =>
        isEqual(prevState, newState),
    );

    const handleSortEnd: SortEndHandler = useCallback((sort) => {
        dispatch(
            notebookSlice.actions.changeCellPosition({
                oldIndex: sort.oldIndex,
                newIndex: sort.newIndex,
            }),
        );
    }, []);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if ([KeyEnum.UP, KeyEnum.DOWN].includes(event.key as KeyEnum)) {
            if (event.key === KeyEnum.UP) {
                dispatch(notebookSlice.actions.upFromCurrentCell());
            }

            if (event.key === KeyEnum.DOWN) {
                dispatch(notebookSlice.actions.downFromCurrentCell());
            }

            event.preventDefault();
        }
    };

    const renderSeparator = useCallback(
        ({index}: {index: number}) => <AddJupyterCellContainer addIndex={index} />,
        [],
    );

    const renderAddCellButton = useCallback(
        ({index}: {index: number}) => <AddJupyterCellButtonContainer addIndex={index} />,
        [],
    );

    const renderPlaceholder = useCallback(() => <NotebookPlaceholderContainer />, []);

    const renderCell = useCallback(
        ({index}: {index: number}) => <JupyterCellContainer cellIndex={index} />,
        [],
    );

    return {
        cellIds,
        handleKeyDown,
        handleSortEnd,
        renderSeparator,
        renderAddCellButton,
        renderPlaceholder,
        renderCell,
    };
};

export const JupyterGridContainer = () => {
    const jupyterGridProps = useJupyterGrid();

    return <JupyterGrid {...jupyterGridProps} />;
};
