import React, {useCallback, useMemo} from 'react';
import {ChevronDown, ChevronUp} from '@gravity-ui/icons';
import {
    Button,
    Flex,
    Icon,
    Select,
    type SelectOption,
    SelectRenderControlProps,
    Text,
} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';
import {NebiusYT} from '../../../../config/ui-config';
import {JupytSelectOption} from './JupytSelectOption/JupytSelectOption';
import {NotebookPageQa} from '../../../../../shared/qa';

import './JupytSelect.scss';
import type {JupytServerHealthType, JupytServerStateType} from 'features/Jupyter/api/jupyt';

type JupytSelectorOptionData = {
    health?: JupytServerHealthType;
    state?: JupytServerStateType;
    dev?: boolean;
    suspended?: boolean;
};

type JupytSelectorProps = {
    jupyts: {
        content?: string;
        value: string;
        data: JupytSelectorOptionData;
    }[];
    onUpdate: (value: string[]) => void;
    onEmptyClick: () => void;
    currentJupyt: [string] | undefined;
};

const b = block('jupyt-select');

const getJupytStatus = (data?: JupytSelectorOptionData | undefined) => {
    return data?.state || 'untracked';
};

export const JupytSelect: React.FC<JupytSelectorProps> = (props: JupytSelectorProps) => {
    const {jupyts, currentJupyt, onUpdate, onEmptyClick} = props;

    const selectorItems: SelectOption<JupytSelectorOptionData>[] = React.useMemo(() => {
        if (NebiusYT.devJupytConfig) {
            return jupyts.concat(
                Object.keys(NebiusYT.devJupytConfig ?? {}).map((item) => ({
                    value: item,
                    content: item,
                    data: {dev: true},
                })),
            );
        }

        return jupyts;
    }, [jupyts]);

    const currentJupytItem = useMemo(() => {
        return jupyts.find((jupyt) => jupyt.value === currentJupyt?.[0]);
    }, [currentJupyt?.[0], jupyts]);

    const renderOption = useCallback(
        (option: SelectOption<JupytSelectorOptionData>) => (
            <JupytSelectOption
                value={option.value}
                status={getJupytStatus(option.data)}
                suspended={option?.data?.suspended}
            />
        ),
        [],
    );

    const renderControl = useCallback(
        (props: SelectRenderControlProps) => {
            if (currentJupytItem) {
                return (
                    <Button
                        {...props}
                        qa={NotebookPageQa.KernelSelect}
                        onClick={props.onClick}
                        className={b('control')}
                        view="outlined"
                        width="max"
                    >
                        <Flex alignItems="center" justifyContent={'spa'} className={b('option')}>
                            <JupytSelectOption
                                qa={NotebookPageQa.KernelSelectText}
                                value={currentJupytItem.value}
                                suspended={currentJupytItem.data.suspended}
                                status={getJupytStatus(currentJupytItem.data)}
                            />
                            <Icon
                                className={b('icon')}
                                data={props.open ? ChevronUp : ChevronDown}
                            />
                        </Flex>
                    </Button>
                );
            }

            return (
                <Button
                    {...props}
                    qa={NotebookPageQa.KernelSelect}
                    onClick={onEmptyClick}
                    view="outlined"
                >
                    <Text variant="body-1">Create kernel</Text>
                </Button>
            );
        },
        [currentJupytItem],
    );

    return (
        <Select
            className={b()}
            popupClassName={b('popup')}
            value={currentJupyt}
            onUpdate={onUpdate}
            multiple={false}
            options={selectorItems}
            filterable={true}
            renderOption={renderOption}
            renderControl={renderControl}
        />
    );
};
