import React from 'react';
import {Button, DropdownMenu, DropdownMenuItem} from '@gravity-ui/uikit';
import Icon from '@ytsaurus-ui-platform/src/ui/components/Icon/Icon';
import {KernelPageQa} from '../../../../../../../shared/qa';
import type {JupytServerStateType} from '../../../../api/jupyt';

export function JupytListTableActions({
    onRemoveClick,
    onStartClick,
    onStopClick,
    onResumeClick,
    isSuspended,
    state,
}: {
    onRemoveClick: () => void;
    onStartClick: () => void;
    onStopClick: () => void;
    onResumeClick?: () => void;
    isSuspended?: boolean;
    state?: JupytServerStateType;
}) {
    const start = {
        icon: <Icon awesome="play-circle" />,
        text: 'Start',
        key: 'start',
        action: () => {
            onStartClick();
        },
    };

    const stop = {
        icon: <Icon awesome="stop-circle" />,
        text: 'Stop',
        key: 'stop',
        action: () => {
            onStopClick();
        },
    };
    const remove = {
        icon: <Icon awesome="trash-alt" />,
        text: 'Remove',
        key: 'remove',
        action: () => {
            onRemoveClick();
        },
    };
    const resume = {
        icon: <Icon awesome="play-circle" />,
        text: 'Resume',
        key: 'resume',
        action: () => {
            onResumeClick?.();
        },
    };

    const items = React.useMemo(() => {
        if (isSuspended && state === 'active') {
            return [resume, stop];
        }

        if (state === 'inactive') {
            return [start];
        }

        return [stop];
    }, [isSuspended, state]);

    const menuItems: Array<Array<DropdownMenuItem>> = [items, [remove]];

    return (
        <DropdownMenu
            popupProps={{
                qa: KernelPageQa.KernelPageActionsPopup,
            }}
            switcher={
                <Button view={'flat'} qa={KernelPageQa.KernelPageActions}>
                    <Icon awesome="ellipsis-h" />
                </Button>
            }
            items={menuItems}
        />
    );
}
