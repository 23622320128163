import React from 'react';
import {ytApiV3} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import {NebiusThunkDispatch} from 'store/nebius-dispatch';
import {NebiusRootState} from 'store/reducers';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import type {SecretStore} from 'features/SecretStore/types/secrets';
import {createNebiusAsyncThunk} from 'store/nebius-async-thunk';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import {genNavigationUrl} from '@ytsaurus-ui-platform/src/ui/utils/navigation/navigation';
import Link from '@ytsaurus-ui-platform/src/ui/components/Link/Link';

export const loadSecretStoreFromCypress = () => {
    return (_dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        const path = getPath(getState());

        return ytApiV3.get({path});
    };
};

type SaveSecretDocumentToCypressPayload = {
    secretStore: SecretStore;
};

export const saveSecretStoreToCypress = ({secretStore}: SaveSecretDocumentToCypressPayload) => {
    return (_dispatch: NebiusThunkDispatch, getState: () => NebiusRootState) => {
        const path = getPath(getState());

        return ytApiV3.set({path}, secretStore);
    };
};

const CREATE_SECRET_STORE_NODE_ACTION = 'navigation.modal.create-secret-store';

export const createSecretStoreInCypress = createNebiusAsyncThunk<void, {path: string}>(
    CREATE_SECRET_STORE_NODE_ACTION,
    async ({path}, thunkAPI) => {
        const promise = ytApiV3.create({
            path,
            type: 'document',
            attributes: {
                tracto_type: 'secret_store',
                inherit_acl: false,
                value: {secrets: {}},
                acl: [
                    {
                        action: 'allow',
                        inheritance_mode: 'object_only',
                        permissions: ['read', 'write', 'administer', 'remove', 'manage'],
                        subjects: ['owner'],
                    },
                ],
            },
        });

        const cluster = getCluster(thunkAPI.getState());
        return wrapApiPromiseByToaster(promise, {
            toasterName: path,
            timeout: 10000,
            successTitle: 'Secret store created',
            errorTitle: 'Secret store creation failure',
            successContent: () => {
                return (
                    <React.Fragment>
                        Secret store <Link url={genNavigationUrl({cluster, path})}>{path}</Link>{' '}
                        successfully created
                    </React.Fragment>
                );
            },
            errorContent: (error) => {
                return `[code ${error.code}] ${error.message}`;
            },
        });
    },
);
