import type {GetSysUserAttributeData} from '../../shared/types/yt-api/v4/users';

export const getSysUserEmail = (userData: GetSysUserAttributeData) => {
    return userData.email ?? userData.principal_name ?? '';
};

export const getSysUserName = (userData: GetSysUserAttributeData, fallback?: string) => {
    return userData.display_name ?? fallback ?? '';
};

export const getDefaultSysUser = (login: string) => {
    return {
        name: login,
        email: '',
    };
};
