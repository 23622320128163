import {WorkflowTriggerType} from 'features/Orchestracto/types/workflow';
import React, {useCallback, useMemo, useState} from 'react';
import CollapsibleSection from '@ytsaurus-ui-platform/src/ui/components/CollapsibleSection/CollapsibleSection';
import MetaTable from '@ytsaurus-ui-platform/src/ui/components/MetaTable/MetaTable';
import Yson from '@ytsaurus-ui-platform/src/ui/components/Yson/Yson';

type WorkflowDetailsComponentProps = {
    version: string;
    triggers: WorkflowTriggerType[];
};

export const WorkflowDetailsComponent: React.FC<WorkflowDetailsComponentProps> = ({
    version,
    triggers,
}) => {
    const items = useMemo(
        () => [
            {
                key: 'version',
                value: <Yson value={version} />,
            },
            {
                key: 'triggers',
                value: <Yson value={triggers} />,
            },
        ],
        [version, triggers],
    );

    const [collaped, setCollapsed] = useState<boolean>(true);

    const onToggle = useCallback((value: boolean) => {
        setCollapsed(value);
    }, []);

    return (
        <CollapsibleSection
            name={collaped ? 'Show details' : 'Hide details'}
            size={'ss'}
            marginDirection="bottom"
            collapsed={true}
            onToggle={onToggle}
        >
            <MetaTable items={items} />
        </CollapsibleSection>
    );
};
