import React, {useCallback, useMemo, useState} from 'react';
import {
    DescriptorType,
    Label,
    type LabelProps,
    Pagination,
    Table,
    TableColumnConfig,
    TableProps,
} from '@gravity-ui/uikit';
import {BlueprintExecutionStatus, BlueprintRunListItem} from 'features/ImageBuilder/types/runs';
import moment from 'moment/moment';
import './BlueprintRunsListComponent.scss';
import block from 'bem-cn-lite';
import Link from '@ytsaurus-ui-platform/src/ui/components/Link/Link';

const b = block('blueprint-runs-list');

const PAGE_SIZE = 16;

interface BlueprintRunsListComponentProps {
    data: BlueprintRunListItem[];
    selectedItemId?: string;
    onRowClick: TableProps<BlueprintRunListItem>['onRowClick'];
}

const statusToLabelMap: Record<BlueprintExecutionStatus, LabelProps['theme']> = {
    success: 'success',
    failure: 'danger',
    unknown: 'unknown',
    running: 'warning',
};

export const BlueprintRunsListComponent: React.FC<BlueprintRunsListComponentProps> = ({
    data,
    selectedItemId,
    onRowClick,
}) => {
    const [page, setPage] = useState(1);

    const columns: TableColumnConfig<BlueprintRunListItem>[] = [
        {
            id: 'id',
            name: 'Id',
            template: (item) => {
                return item.key;
            },
        },
        {
            id: 'status',
            name: 'Status',
            template: (item) => {
                return <Label theme={statusToLabelMap[item.status]}>{item.status}</Label>;
            },
        },
        {
            id: 'link-to-logs',
            name: 'Logs',
            template: (item) => {
                return <Link url={item.linkToLogs}>Logs</Link>;
            },
        },
        {
            id: 'start-time',
            name: 'Start time',
            template: (item) => {
                return moment(item.startTime).format('DD/MM/YYYY HH:mm:ss');
            },
        },
    ];

    const getRowDescriptor = useCallback(
        (row: BlueprintRunListItem) => {
            const descriptor: DescriptorType = {
                classNames: [b('row', {selected: row.key === selectedItemId})],
            };

            return descriptor;
        },
        [selectedItemId],
    );

    const handleUpdate = useCallback((page: number) => {
        setPage(page);
    }, []);

    const pagedData = useMemo(() => {
        return data.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    }, [data, page]);

    return (
        <div className={b()}>
            <Table
                className={b('table')}
                data={pagedData}
                columns={columns}
                onRowClick={onRowClick}
                getRowDescriptor={getRowDescriptor}
            />
            <Pagination
                className={b('pagination')}
                compact={false}
                page={page}
                pageSize={PAGE_SIZE}
                total={data.length}
                onUpdate={handleUpdate}
            />
        </div>
    );
};
