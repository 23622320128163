import React from 'react';
import {Icon} from '@gravity-ui/uikit';
import {LogoDocker} from '@gravity-ui/icons';
import {ExtraTab} from '@ytsaurus-ui-platform/src/ui/UIFactory';
import {Tab as NavigationTab} from '@ytsaurus-ui-platform/src/ui/constants/navigation';

import type {Node} from '@ytsaurus-ui-platform/src/ui/utils/navigation/content/map-nodes/node';
import {TractoRegistryTab} from './components/TractoRegistryTab/TractoRegistryTab';

const isTractoRegistryNode = (attributes: Record<string, string | boolean> | undefined) =>
    attributes?.key === '_tags' || attributes?.is_docker_image === true;

export const TRACTO_REGISTRY_TAB: ExtraTab = {
    title: 'Registry',
    value: 'extra_image',
    text: 'Image',
    component: TractoRegistryTab,
    position: {before: NavigationTab.CONTENT},
    isSupported: (attributes) => isTractoRegistryNode(attributes),
};

export const TRACTO_REGISTRY_NAVIGATION_MAP_NODE_SETTINGS = {
    additionalAttributes: ['key'],
    renderNodeIcon: (item: Node) => {
        if (isTractoRegistryNode(item.$attributes)) {
            return <Icon data={LogoDocker} size={14} />;
        }

        return null;
    },
};
