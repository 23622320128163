import React, {ComponentProps, useCallback} from 'react';
import {BlueprintRunsListComponent} from 'features/ImageBuilder/components/BlueprintRunsListComponent/BlueprintRunsListComponent';
import type {BlueprintRunListItem} from 'features/ImageBuilder/types/runs';
import type {TableProps} from '@gravity-ui/uikit';

const mockData: BlueprintRunListItem[] = (
    ['unknown', 'running', 'success', 'failure'] as const
).map((status) => {
    return {
        key: '6b30ab23-b7d4-4cfd-8663-ec775a19135c',
        startTime: 'Fri Nov 22 2024 10:33:14 GMT+0100 (GMT+01:00)',
        linkToLogs: '/fake/link',
        status,
    };
});

export const BlueprintRunsListContainer: React.FC = () => {
    const data = mockData;

    const selectedItemId = '';

    const onRowClick: TableProps<BlueprintRunListItem>['onRowClick'] = useCallback(() => {}, []);

    const props: ComponentProps<typeof BlueprintRunsListComponent> = {
        data,
        selectedItemId,
        onRowClick,
    };

    return <BlueprintRunsListComponent {...props} />;
};
