import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

export const setupWorkflowJSONValidation = () => {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        schemaValidation: 'error',
        schemas: [
            {
                uri: 'http://json-schema.org/draft-07/schema#',
                fileMatch: ['*'],
                schema: {
                    title: 'WorkflowDocument',
                    type: 'object',
                    properties: {
                        _format_version: {
                            type: 'string',
                            description: 'The version of the workflow format.',
                        },
                        triggers: {
                            type: 'array',
                            description: 'List of triggers for the workflow.',
                            items: {
                                type: 'object',
                                properties: {
                                    trigger_type: {
                                        type: 'string',
                                        description: 'Type of trigger.',
                                    },
                                    params: {
                                        type: 'object',
                                        description: 'Parameters for the trigger.',
                                        properties: {
                                            cron_expression: {
                                                type: 'string',
                                                description:
                                                    'Cron expression that described workflow details of the schedule.',
                                            },
                                        },
                                        additionalProperties: true,
                                    },
                                },
                            },
                        },
                        steps: {
                            type: 'array',
                            description: 'Sequence of steps in the workflow.',
                            items: {
                                type: 'object',
                                properties: {
                                    step_id: {
                                        type: 'string',
                                        description: 'Unique identifier for the step.',
                                    },
                                    task_type: {
                                        type: 'string',
                                        description: 'Type of task to be executed in this step.',
                                    },
                                    task_params: {
                                        type: 'object',
                                        description: 'Parameters for the task.',
                                        additionalProperties: true,
                                    },
                                    secrets: {
                                        type: 'array',
                                        description:
                                            'Optional list of secrets required for this step.',
                                        items: {
                                            type: 'object',
                                            properties: {
                                                key: {
                                                    type: 'string',
                                                    description: 'Key for the secret.',
                                                },
                                                value_ref: {
                                                    type: 'string',
                                                    description: "Reference to the secret's value.",
                                                },
                                            },
                                        },
                                    },
                                    depends_on: {
                                        type: 'array',
                                        description:
                                            'Optional list of step IDs this step depends on.',
                                        items: {
                                            type: 'string',
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        ],
    });
};
