import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {WorkflowRunData, WorkflowRunListItem} from 'features/Orchestracto/types/runs';

type WorkflowRunsState = {
    runId: string | undefined;
    runs: WorkflowRunListItem[];
    isRunsLoaded: boolean;
    currentRun: WorkflowRunData | null;
    currentRunLoading: boolean;
};

const initialState: WorkflowRunsState = {
    runId: undefined,
    runs: [],
    isRunsLoaded: false,
    currentRun: null,
    currentRunLoading: false,
};

export const runsSlice = createSlice({
    name: 'orchestracto.runs',
    initialState,
    reducers: {
        setRuns: (state, action: PayloadAction<{runs: WorkflowRunListItem[]}>) => {
            state.runs = action.payload.runs;
            state.isRunsLoaded = true;
        },
        setCurrentRun: (state, action: PayloadAction<{currentRun: WorkflowRunData | null}>) => {
            state.currentRun = action.payload.currentRun;
        },
        setCurrentRunLoading: (state, action: PayloadAction<{loading: boolean}>) => {
            state.currentRunLoading = action.payload.loading;
        },
        clearState: () => initialState,
    },
});
