import moment from 'moment/moment';
import {DockerImageConfig} from './store/slices/manifests';
import hammer from '@ytsaurus-ui-platform/src/ui/common/hammer';

export const modifySpecificAttributeTypes = function (key: string, value: any) {
    switch (key) {
        case 'created':
            return {key, value: moment(value).format('DD/MM/YYYY HH:mm:ss')};
        case 'created_by': {
            return {
                key,
                ...parseCreatedBy(value),
            };
        }
        case 'size':
            return {key, value: bytesToSize(value)};
        case 'Entrypoint':
        case 'Cmd':
            return {key, value: (value || []).join(' ')};
        case 'Labels':
            return {
                key,
                value: Object.keys(value || {}).map(function (elt) {
                    return value[elt] ? elt + '=' + value[elt] : '';
                }),
            };
        case 'Volumes':
        case 'ExposedPorts':
            return {key, value: Object.keys(value)};
    }

    return {key, value: value || ''};
};

export const getBlobConfig = function (blobs: DockerImageConfig) {
    const res = [
        'architecture',
        'User',
        'created',
        'docker_version',
        'os',
        'Cmd',
        'Entrypoint',
        'Env',
        'Labels',
        'User',
        'Volumes',
        'WorkingDir',
        'author',
        'id',
        'ExposedPorts',
        'name',
        'appVersion',
        'kubeVersion',
        'keywords',
        'home',
        'sources',
    ].reduce(
        (acc, e) => {
            const value =
                blobs[e as keyof DockerImageConfig] ||
                blobs?.config[e as keyof DockerImageConfig['config']];
            if (value && e === 'architecture' && blobs.variant) {
                acc[e] = value + blobs.variant;
            } else if (value) {
                acc[e] = value;
            }
            return acc;
        },
        {} as Record<string, unknown>,
    );

    if (blobs.config.Labels.maintainer) {
        res.author = blobs.config.Labels.maintainer;
    }

    return res;
};

export const parseCreatedBy = (value: string) => {
    if (value.startsWith('COPY')) {
        return {
            value: 'COPY',
            content: value.replace(/^COPY /, ''),
        };
    }
    const cmd = value.match(/\/bin\/sh *-c *#\(nop\) *([A-Z]+) (.*)/);

    return {
        value: (cmd && cmd[1]) || 'RUN',
        content: (cmd && cmd[2]) || value.replace(/^\/bin\/sh *-c *(#\(nop\))?/, ''),
    };
};

export function bytesToSize(bytes: number) {
    // eslint-disable-next-line new-cap
    return hammer.format['Bytes'](bytes).toString();
}
