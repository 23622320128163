import {KernelManager, ServerConnection, Session, SessionManager} from '@jupyterlab/services';
import {NebiusLogger} from '../../../../utils/logging';
import {
    getHttpRequestInit,
    getJupyterBaseUrl,
} from '../../../../../shared/utils/jupyter/jupyter-api';

let sessionManagerSingleton: SessionManager | null;

export const getOptionalSessionManager = () => sessionManagerSingleton;

export const getSessionManager = (
    kernelManager: KernelManager,
    {alias, cluster, path}: {alias: string; cluster: string; path: string},
): SessionManager => {
    if (sessionManagerSingleton) {
        return sessionManagerSingleton;
    }

    const serverSettings = ServerConnection.makeSettings({
        baseUrl: getJupyterBaseUrl({cluster, alias}),
        init: getHttpRequestInit({path}),
    });

    sessionManagerSingleton = new SessionManager({serverSettings, kernelManager});

    return sessionManagerSingleton;
};

export const createSession = (
    sessionManager: SessionManager,
    {path, notebookId}: {path: string; notebookId: string},
): Promise<Session.ISessionConnection> => {
    NebiusLogger.log(
        'Starting session connection with path: ',
        path,
        'and notebookId: ',
        notebookId,
    );

    return sessionManager.startNew({
        type: 'notebook',
        path: `${path}_${notebookId}`,
        name: '',
    });
};

export const resetSessionManager = () => {
    sessionManagerSingleton = null;
};
