import React from 'react';
import reduce_ from 'lodash/reduce';
import cn from 'bem-cn-lite';
import {Loader} from '@gravity-ui/uikit';

import {YTError} from '@ytsaurus-ui-platform/src/@types/types';
import Yson from '@ytsaurus-ui-platform/src/ui/components/Yson/Yson';
import Icon from '@ytsaurus-ui-platform/src/ui/components/Icon/Icon';
import Error from '@ytsaurus-ui-platform/src/ui/components/Error/Error';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import {UnipikaSettings} from '@ytsaurus-ui-platform/src/ui/components/Yson/StructuredYson/StructuredYsonTypes';
import {StrawberryDescribeOptionsType} from '@ytsaurus-ui-platform/src/ui/utils/strawberryControllerApi';
import {
    linkPoolWithPoolTree,
    makeTabbedDialogFieldsFromDescription,
} from '@ytsaurus-ui-platform/src/ui/components/Dialog/df-dialog-utils';
import {
    WaitForDefaultPoolTree,
    usePoolTreesLoaded,
} from '@ytsaurus-ui-platform/src/ui/hooks/global-pool-trees';

import './JupytPageOperationSpeclet.scss';
import {KernelPageQa} from '../../../../../../shared/qa';
import {JupytOperationState} from '../../../store/slices/jupytOperation';
import {JupytListResponseItem} from 'features/Jupyter/api/jupyt';

const block = cn('jupyt-speclet');

type OnEditOptions = (
    alias: string,
    options: Required<JupytListResponseItem>['$attributes'],
) => Promise<unknown>;

interface JupytPageOperationSpecletProps {
    alias: string;
    speclet: JupytOperationState['speclet'];
    options: JupytOperationState['options'];
    onEditOptions: OnEditOptions;
    unipikaSettings: UnipikaSettings;
}

export const JupytPageOperationSpeclet = (props: JupytPageOperationSpecletProps) => {
    const isLoading = !props.speclet.data && !props.speclet.error;

    return (
        <React.Fragment>
            {isLoading ? (
                <div className={block('loader')}>
                    <Loader size="s" />
                </div>
            ) : (
                <React.Fragment>
                    {props.options.data ? (
                        <div className={block('edit')}>
                            <JupytSpecletEditButton
                                alias={props.alias}
                                options={props.options.data}
                                unipikaSettings={props.unipikaSettings}
                                onEditOptions={props.onEditOptions}
                            />
                        </div>
                    ) : null}
                    {props.speclet.error ? (
                        <Error
                            className={block('raw-speclet-error')}
                            error={props.speclet.error}
                            bottomMargin
                        />
                    ) : (
                        <JupytSpeclet
                            speclet={props.speclet.data}
                            unipikaSettings={props.unipikaSettings}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

function JupytSpeclet({
    unipikaSettings,
    speclet,
}: {
    speclet: unknown;
    unipikaSettings: UnipikaSettings;
}) {
    return (
        <div className={block()} data-qa={KernelPageQa.KernelPageSpecletInfo}>
            <Yson
                folding
                value={speclet}
                settings={unipikaSettings}
                className={block('raw-speclet')}
            />
        </div>
    );
}

interface JupytSpecletEditButtonProps {
    alias: string;
    onEditOptions: OnEditOptions;
    options: StrawberryDescribeOptionsType;
    unipikaSettings: UnipikaSettings;
}

export function JupytSpecletEditButton({
    alias,
    options,
    onEditOptions,
    unipikaSettings,
}: JupytSpecletEditButtonProps) {
    const [visible, setVisible] = React.useState(false);

    usePoolTreesLoaded();

    const onClose = React.useCallback(() => {
        setVisible(false);
    }, []);

    return (
        <React.Fragment>
            {!visible ? null : (
                <WaitForDefaultPoolTree>
                    {({defaultPoolTree}) => (
                        <JupytSpecletEditDialog
                            key={alias}
                            data={options}
                            alias={alias}
                            allowEdit={true}
                            unipikaSettings={unipikaSettings}
                            onEditOptions={onEditOptions}
                            onClose={onClose}
                            defaultPoolTree={defaultPoolTree}
                        />
                    )}
                </WaitForDefaultPoolTree>
            )}
            <Button title={'Edit speclet'} onClick={() => setVisible(!visible)} disabled={false}>
                <Icon awesome={'pencil'} />
                Edit speclet
            </Button>
        </React.Fragment>
    );
}

// We are using uniq field type
const pathFieldsType = (groups: ReturnType<typeof makeTabbedDialogFieldsFromDescription>) => {
    groups.fields?.forEach((filed) => {
        filed.fields.forEach((item) => {
            if ('name' in item && item?.name === 'idle_timeout') {
                item.type = 'duration' as any;
            }
        });
    });
};

function JupytSpecletEditDialog({
    alias,
    data,
    onClose,
    allowEdit,
    onEditOptions,
    unipikaSettings,
    defaultPoolTree,
}: {
    alias: string;
    onClose: () => void;
    allowEdit: boolean;
    data: StrawberryDescribeOptionsType;
    unipikaSettings: UnipikaSettings;
    onEditOptions: OnEditOptions;
    defaultPoolTree: string;
}) {
    const [error, setError] = React.useState<YTError | undefined>();

    const {fields, initialValues, fieldTypeByName} = React.useMemo(() => {
        const groups = makeTabbedDialogFieldsFromDescription(data ?? [], {
            allowEdit,
            unipikaSettings,
            defaultPoolTree,
        });

        pathFieldsType(groups);

        linkPoolWithPoolTree(groups);

        return groups;
    }, [data, allowEdit, unipikaSettings]);

    return (
        <React.Fragment>
            {error && <Error bottomMargin error={error} />}
            <YTDFDialog
                className={block('dialog')}
                size="l"
                visible
                onClose={onClose}
                onAdd={(form) => {
                    const {values: formValues} = form.getState();
                    const values = reduce_(
                        formValues,
                        (acc, tabValues) => {
                            return {...acc, ...tabValues};
                        },
                        {},
                    );

                    const {restart_on_speclet_change} = values as any;
                    const initials = reduce_(
                        initialValues,
                        (acc, tabValues) => {
                            return {...acc, ...tabValues};
                        },
                        {},
                    );
                    const diff = reduce_(
                        values as any,
                        (acc, value, key) => {
                            const oldValue = initials[key as keyof typeof initials];
                            const {converter} = fieldTypeByName[key];
                            const oldV = converter.fromFieldValue(oldValue);
                            const v = converter.fromFieldValue(value, oldV);
                            if (v !== oldV) {
                                if (v !== null && v !== undefined && v !== '') {
                                    acc[key] = v;
                                } else {
                                    acc[key] = undefined;
                                }
                            }
                            return acc;
                        },
                        {restart_on_speclet_change} as Record<string, unknown>,
                    );

                    return onEditOptions(
                        alias,
                        diff as Required<JupytListResponseItem>['$attributes'],
                    )
                        .then(() => {
                            setError(undefined);
                        })
                        .catch((e: any) => setError(e));
                }}
                fields={fields}
                initialValues={initialValues}
                headerProps={{
                    title: <span>Edit kernel {alias}</span>,
                }}
            />
        </React.Fragment>
    );
}
