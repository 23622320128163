import {SecretsList} from 'components/SecretsList/SecretsList';
import React, {ComponentProps, useState} from 'react';
import './BlueprintCredentialsComponent.scss';
import block from 'bem-cn-lite';

const b = block('blueprint-credentials');

type BlueprintCredentialsComponentProps = {
    className?: string;
};

export const BlueprintCredentialsComponent: React.FC<BlueprintCredentialsComponentProps> = ({
    className,
}) => {
    const [value, setValue] = useState<ComponentProps<typeof SecretsList>['value']>([]);

    return (
        <div className={b(null, className)}>
            <div className="elements-heading elements-heading_size_xs">Credentials</div>
            <SecretsList value={value} onUpdate={setValue} />
        </div>
    );
};
