import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchImage = createAsyncThunk(
    'tracto-registry/fetchImages',
    async (opts: {cluster: string; repository: string}) => {
        return fetch(`/${opts.cluster}/tracto-registry/v2/${opts.repository}/tags/list`).then(
            (res) => res.json() as unknown as RepositoryTag,
        );
    },
);

interface RepositoryTag {
    name: string;
    tags: string[];
}

interface ImageSliceState {
    tags: string[];
    repository: string;
    status: 'idle' | 'loading' | 'failed';
}

const initialState = {
    repository: '',
    tags: [],
    status: 'idle',
} satisfies ImageSliceState as ImageSliceState;

export const imageSlice = createSlice({
    name: 'image',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchImage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchImage.fulfilled, (state, action) => {
                state.status = 'idle';
                state.tags = action.payload.tags;
                state.repository = action.payload.name;
            })
            .addCase(fetchImage.rejected, (state) => {
                state.status = 'failed';
            });
    },
});
