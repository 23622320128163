import {KernelMessage} from '@jupyterlab/services';
import * as nbformat from '@jupyterlab/nbformat';
import {NebiusThunkDispatch, SliceActionType} from '../../../../store/nebius-dispatch';
import {NebiusRootState} from '../../../../store/reducers';
import {selectCellOutputs, selectFocusedCell, selectNotebookCells} from '../selectors/notebook';
import {
    isDisplayDataMsg,
    isErrorMsg,
    isExecuteInputMsg,
    isExecuteReplyMsg,
    isExecuteResultMsg,
    isStreamMsg,
    isUpdateDisplayDataMsg,
} from '@jupyterlab/services/lib/kernel/messages';
import {JupyterApi} from '../../api/kernel';
import {extractCellId} from '../../utils/cell';
import {NebiusLogger} from 'utils/logging';
import {notebookSlice} from '../slices/notebook';
import {getItemStrict} from '../../utils/strict-selectors';
import {selectJupytOperations} from '../selectors/jupyt';
import {asyncOpenCreateJupytDialog} from './modals/modal-create-jupyt';
import {loadNotebookJupytList, setInitialJupytAlias} from './jupyt/jupyt';
import {wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';

type NotebookThunkDispatch = NebiusThunkDispatch<SliceActionType<typeof notebookSlice.actions>>;

const _executeNotebook = () => {
    return async (dispatch: NotebookThunkDispatch, getState: () => NebiusRootState) => {
        const cells = getItemStrict(selectNotebookCells(getState()));

        dispatch(notebookSlice.actions.runAllCells());

        for (const cell of cells) {
            if (nbformat.isCode(cell)) {
                await dispatch(_executeCell({cell, waitForCellExecution: false}));
            }
        }
    };
};

export const executeNotebookWithRequiredJupyt = () => {
    return async (dispatch: NotebookThunkDispatch, getState: () => NebiusRootState) => {
        const state = getState();

        const operations = selectJupytOperations(state);
        return operations.length
            ? dispatch(_executeNotebook())
            : dispatch(asyncOpenCreateJupytDialog()).then((result) =>
                  result.payload ? dispatch(_executeNotebook()) : null,
              );
    };
};

export const executeFocusedCell = () => {
    return async (dispatch: NotebookThunkDispatch, getState: () => NebiusRootState) => {
        const cell = selectFocusedCell(getState());

        if (!cell) {
            return;
        }

        dispatch(notebookSlice.actions.removeCellEditable());

        if (nbformat.isCode(cell)) {
            dispatch(executeCellWithRequiredJupyt({cell}));
        }

        dispatch(focusToNextCell({cell}));
    };
};

const focusToNextCell = ({cell}: {cell: nbformat.ICell}) => {
    return async (dispatch: NotebookThunkDispatch, getState: () => NebiusRootState) => {
        const cells = selectNotebookCells(getState());
        const cellIndex = cells.findIndex((item) => extractCellId(item) === extractCellId(cell));
        const lastItemIndex = cells.length - 1;

        if (cellIndex === lastItemIndex) {
            dispatch(
                notebookSlice.actions.addCellAfter({
                    currentIndex: lastItemIndex,
                    type: nbformat.isCode(cell) ? 'code' : 'markdown',
                }),
            );
        } else {
            const nextCell = cells[cellIndex + 1];
            dispatch(notebookSlice.actions.setFocusedCellById({cellId: extractCellId(nextCell)}));
        }
    };
};

export const interruptExecution = () => {
    return async (dispatch: NotebookThunkDispatch) => {
        const kernel = await JupyterApi.getKernelConnection();

        await kernel.interrupt();

        dispatch(notebookSlice.actions.interruptExecution());
    };
};

const _executeCell = ({
    cell,
    waitForCellExecution = true,
}: {
    cell: nbformat.ICodeCell;
    waitForCellExecution?: boolean;
}) => {
    return async (dispatch: NotebookThunkDispatch, getState: () => NebiusRootState) => {
        const cellId = extractCellId(cell);
        const code = typeof cell.source === 'string' ? cell.source : cell.source.join('');

        dispatch(notebookSlice.actions.startCellExecution({cellId}));

        const kernel = await JupyterApi.getKernelConnection();

        dispatch(
            notebookSlice.actions.setCellOutputs({
                cellId,
                outputs: [],
            }),
        );

        const request = kernel.requestExecute({
            code,
            silent: false,
            stop_on_error: true,
            allow_stdin: true,
            store_history: true,
        });

        request.onIOPub = (msg) => {
            dispatch(hanldeIOPubMessage({msg, cellId}));
        };

        request.onReply = (msg) => {
            NebiusLogger.log('Incoming reply message: ', msg);

            if (isExecuteReplyMsg(msg)) {
                const executeReplyOutputs = getOuputsFromExecuteReply(msg);
                const outputs = selectCellOutputs(getState(), cellId);

                dispatch(
                    notebookSlice.actions.setCellOutputs({
                        cellId,
                        outputs: [...outputs, ...executeReplyOutputs],
                    }),
                );
            }
        };

        request.onStdin = (msg) => {
            NebiusLogger.log('Incoming stdin message: ', msg);
        };

        wrapApiPromiseByToaster(request.done, {
            skipSuccessToast: true,
            errorTitle: 'Cell execution failed',
            errorContent: (error) => error.message,
            toasterName: 'JupyterCellExecution',
        })
            .then((executeReplyMessage: KernelMessage.IExecuteReplyMsg) => {
                dispatch(notebookSlice.actions.finishCellExecution({cellId}));

                NebiusLogger.log('Execute done message: ', executeReplyMessage);
            })
            .catch(() => {
                dispatch(notebookSlice.actions.interruptExecution());
            });

        if (waitForCellExecution) {
            await request.done;
        }
    };
};

export const executeCellByClick = ({cell}: {cell: nbformat.ICodeCell}) => {
    return (dispatch: NotebookThunkDispatch) => {
        dispatch(executeCellWithRequiredJupyt({cell}));
    };
};

const executeCellWithRequiredJupyt = ({cell}: {cell: nbformat.ICodeCell}) => {
    return async (dispatch: NotebookThunkDispatch, getState: () => NebiusRootState) => {
        const state = getState();
        const operations = selectJupytOperations(state);

        if (operations.length) {
            return dispatch(_executeCell({cell}));
        }

        const result = await dispatch(asyncOpenCreateJupytDialog());

        if (!result.payload) {
            return null;
        }

        await dispatch(loadNotebookJupytList());

        dispatch(setInitialJupytAlias());

        await dispatch(_executeCell({cell}));
    };
};

type HanldeIOPubMessagePayload = {
    msg: KernelMessage.IIOPubMessage<KernelMessage.IOPubMessageType>;
    cellId: string;
};

const hanldeIOPubMessage = ({msg, cellId}: HanldeIOPubMessagePayload) => {
    return (dispatch: NotebookThunkDispatch) => {
        NebiusLogger.log('Incoming IOPub message: ', msg);

        if (isErrorMsg(msg)) {
            const {ename, evalue, traceback} = msg.content;
            const output: nbformat.IError = {
                output_type: 'error',
                ename,
                evalue,
                traceback,
            };

            dispatch(
                notebookSlice.actions.addCellOutput({
                    cellId,
                    output,
                }),
            );

            return;
        }

        if (isStreamMsg(msg)) {
            const {name, text} = msg.content;
            const output: nbformat.IStream = {
                output_type: 'stream',
                name,
                text,
            };

            dispatch(
                notebookSlice.actions.addCellOutput({
                    cellId,
                    output,
                }),
            );

            return;
        }

        if (isDisplayDataMsg(msg)) {
            const {data, metadata, transient} = msg.content;

            addTransientToMetadata(metadata, transient);

            const output: nbformat.IDisplayData = {
                output_type: 'display_data',
                data,
                metadata,
            };

            dispatch(
                notebookSlice.actions.addCellOutput({
                    cellId,
                    output,
                }),
            );

            return;
        }

        if (isExecuteResultMsg(msg)) {
            const {execution_count, data, metadata, transient} = msg.content;

            addTransientToMetadata(metadata, transient);

            const output: nbformat.IExecuteResult = {
                output_type: 'execute_result',
                execution_count,
                data,
                metadata,
            };

            dispatch(
                notebookSlice.actions.addCellOutput({
                    cellId,
                    output,
                }),
            );

            return;
        }

        if (isUpdateDisplayDataMsg(msg)) {
            dispatch(
                notebookSlice.actions.updateCellDisplayData({
                    msg,
                }),
            );

            return;
        }

        if (isExecuteInputMsg(msg)) {
            dispatch(
                notebookSlice.actions.setCellExecuteCount({
                    cellId,
                    execution_count: msg.content.execution_count,
                }),
            );

            return;
        }
    };
};

function addTransientToMetadata(
    metadata: nbformat.OutputMetadata,
    transient:
        | {
              display_id?: string | undefined;
          }
        | undefined,
) {
    // https://jupyter-client.readthedocs.io/en/latest/messaging.html#display-data
    // Optional transient data introduced in 5.1. Information not to be
    // persisted to a notebook or other documents. Intended to live only
    // during a live kernel session.
    metadata.transient = transient;
}

function getOuputsFromExecuteReply(msg: KernelMessage.IExecuteReplyMsg) {
    const reply = msg.content as KernelMessage.IExecuteReply;

    const outputs: nbformat.IOutput[] = [];

    if (reply.payload) {
        reply.payload.forEach((payload) => {
            if (payload.data && Object.hasOwnProperty.call(payload.data, 'text/plain')) {
                const output: nbformat.IOutput = {
                    output_type: 'stream',
                    text: (payload.data as any)['text/plain'].toString(),
                    name: 'stdout',
                    metadata: {},
                    execution_count: reply.execution_count,
                };

                outputs.push(output);
            }
        });
    }

    return outputs;
}

export const restartKernel = () => {
    return () => {
        JupyterApi.restartKernel();
    };
};
