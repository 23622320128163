import {WorkflowStepType} from 'features/Orchestracto/types/workflow';
import {createSelector} from 'reselect';
import {NebiusRootState} from 'store/reducers';

export const selectCurrentRun = (state: NebiusRootState) =>
    state.nebius.orchestracto.runs.currentRun;

export const selectRuns = (state: NebiusRootState) => state.nebius.orchestracto.runs.runs;

export const selectCurrentRunLoading = (state: NebiusRootState) =>
    state.nebius.orchestracto.runs.currentRunLoading;

export const selectIsRunsLoaded = (state: NebiusRootState) =>
    state.nebius.orchestracto.runs.isRunsLoaded;

export const selectCurrentRunStepsMap = createSelector([selectCurrentRun], (currentRun) => {
    let stepsMap: Record<string, WorkflowStepType> = {};

    if (currentRun?.workflow) {
        stepsMap = currentRun.workflow.steps.reduce(
            (acc: Record<string, WorkflowStepType>, step) => {
                acc[step.step_id] = step;
                return acc;
            },
            {},
        );
    }

    return stepsMap;
});

export const selectRunId = (state: NebiusRootState) => state.nebius.orchestracto.runs.runId;
