import React from 'react';
import block from 'bem-cn-lite';
import {Text} from '@gravity-ui/uikit';
import './AddJupyterCellButton.scss';
import {NotebookPageQa} from '../../../../../shared/qa';

const b = block('add-jupyter-cell-button');

type AddJupyterCellButtonProps = {
    onClick: () => void;
};

export const AddJupyterCellButton = ({onClick}: AddJupyterCellButtonProps) => {
    return (
        <div data-qa={NotebookPageQa.AddCellControl} className={b()} onClick={onClick}>
            <Text variant="body-2">Click to add a cell</Text>
        </div>
    );
};
