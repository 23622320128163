import React from 'react';
import FlexSplitPane from '@ytsaurus-ui-platform/src/ui/components/FlexSplitPane/FlexSplitPane';
import block from 'bem-cn-lite';
import './WorkflowRunsTabComponent.scss';
import {WorkflowRunsListContainer} from 'features/Orchestracto/containers/WorkflowRunsListContainer/WorkflowRunsListContainer';
import {WorkflowExecutionGraphContainer} from '../../containers/WorkflowExecutionGraphContainer/WorkflowExecutionGraphContainer';

const b = block('workflow-runs');

export const WorkflowRunsTabComponent: React.FC = () => {
    return (
        <FlexSplitPane className={b('split-pane')} direction={FlexSplitPane.HORIZONTAL}>
            <WorkflowRunsListContainer />
            <WorkflowExecutionGraphContainer />
        </FlexSplitPane>
    );
};
