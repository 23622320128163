import {WorkflowRunsListComponent} from 'features/Orchestracto/components/WorkflowRunsListComponent/WorkflowRunsListComponent';
import {selectRunId, selectRuns} from 'features/Orchestracto/store/selectors/runs';
import {runsSlice} from 'features/Orchestracto/store/slices/runs';
import React, {useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNebiusDispatch} from '../../../../store/nebius-dispatch';
import {WorkflowRunListItem} from '../../types/runs';
import {loadWorkflowRunState} from '../../store/actions/runs';
import {useHistory, useLocation} from 'react-router';

export const WorkflowRunsListContainer: React.FC = () => {
    const dispatch = useNebiusDispatch();
    const data = useSelector(selectRuns);
    const history = useHistory();
    const location = useLocation();
    const runId = useSelector(selectRunId);

    useEffect(() => {
        if (runId) {
            dispatch(runsSlice.actions.setCurrentRunLoading({loading: true}));
            dispatch(loadWorkflowRunState({runId: runId}))
                .unwrap()
                .then((currentRun) => {
                    dispatch(runsSlice.actions.setCurrentRun({currentRun}));
                    dispatch(runsSlice.actions.setCurrentRunLoading({loading: false}));
                });
        }
    }, [runId]);

    const onRowClick = useCallback((item: WorkflowRunListItem) => {
        const searchParams = new URLSearchParams(location.search);

        searchParams.set('runId', item.key);

        const newUrl = `${location.pathname}?${searchParams.toString()}`;

        history.push(newUrl);
    }, []);

    return <WorkflowRunsListComponent data={data} onRowClick={onRowClick} selectedItemId={runId} />;
};
