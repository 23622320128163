import {createSlice} from '@reduxjs/toolkit';

type DashboardSliceState = {
    spd__state: string;
};

const initialState: DashboardSliceState = {
    spd__state: '',
};

export const dashboardSlice = createSlice({
    name: 'monitoring.dashboard',
    initialState,
    reducers: {},
});
