import React from 'react';
import block from 'bem-cn-lite';
import {Icon, Label, Text} from '@gravity-ui/uikit';
import {Eye} from '@gravity-ui/icons';
import {
    WorkflowExecutionStage,
    WorkflowExecutionStageState,
    WorkflowStepType,
} from 'features/Orchestracto/types/workflow';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {Graph, GraphBlock} from '@gravity-ui/graph';
import './WorkflowStepComponent.scss';
import {WorkflowStepExecutionStatus} from '../WorkflowStepExecutionStatus/WorkflowStepExecutionStatus';
import type {WorkflowBlock} from '../../types/graph';

const b = block('workflow-step-component');

export type WorkflowStepComponentProps = {
    graph: Graph;
    block: WorkflowBlock;
    step: WorkflowStepType;
    onShowDetailsClick?: () => void;
    workflowState:
        | {
              stage: WorkflowExecutionStage;
              finalState: WorkflowExecutionStageState['final_state'];
              finished: boolean;
          }
        | undefined;
};

export const WorkflowStepComponent: React.FC<WorkflowStepComponentProps> = ({
    graph,
    block,
    step,
    workflowState,
    onShowDetailsClick,
}) => {
    const headerMixins = workflowState
        ? {
              stage: workflowState.stage,
              state: workflowState.finalState,
          }
        : null;

    return (
        <GraphBlock graph={graph} block={block}>
            <div className={b()}>
                <div className={b('header', headerMixins)}>
                    {workflowState && <WorkflowStepExecutionStatus {...workflowState} />}
                    <span className={b('title')}>
                        <Text variant="subheader-3">ID:</Text>&nbsp;
                        <Text variant="subheader-2">{step.step_id}</Text>
                    </span>
                </div>
                <div className={b('content')}>
                    <div className={b('type')}>
                        <Label className={b('type')} theme="info" value={step.task_type}>
                            Type
                        </Label>
                    </div>
                    <Button className={b('details')} onClick={onShowDetailsClick}>
                        <Icon data={Eye} />
                    </Button>
                </div>
            </div>
        </GraphBlock>
    );
};
