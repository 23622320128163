import React from 'react';
import CustomRadioButton from '@ytsaurus-ui-platform/src/ui/components/RadioButton/RadioButton';
import block from 'bem-cn-lite';

const b = block('blueprint-template');

const TEMPLATES = ['Empty', 'Tractorun + JAX', 'Tractorun + Pytorch'];

type BlueprintTemplateComponentProps = {
    className?: string;
};

export const BlueprintTemplateComponent: React.FC<BlueprintTemplateComponentProps> = ({
    className,
}) => {
    const items = TEMPLATES.map(CustomRadioButton.prepareSimpleValue);

    return (
        <div className={b(null, className)}>
            <div className="elements-heading elements-heading_size_xs">Template</div>
            <div>
                <CustomRadioButton value={TEMPLATES[0]} items={items} />
            </div>
        </div>
    );
};
